import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_ILLUSTUSERRUNNING: (state, payload) => {
      return { 
        illustrationGeneratorStore : {
          ...state.illustrationGeneratorStore,
          illustUserRunning: payload, 
        }
      };
    },
    SET_UPSCALESERVERPROCESSING: (state, payload) => {
      return { 
        illustrationGeneratorStore : {
          ...state.illustrationGeneratorStore,
          upscaleServerProcessing: payload, 
        }
      };
    },
  };

  initStore(actions, {
    illustrationGeneratorStore: {
      illustUserRunning: false,
      upscaleServerProcessing: false,
    }
  });
};

export default configureStore;
