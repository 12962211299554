import { useState, useEffect, Fragment } from 'react';
// import _, { isLength } from 'lodash';
// import axios from 'axios';
import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';

import FilesUploadAction from '../FilesUploadAction/FilesUploadAction';
// import BucketObjectList from './BucketObjectList';
import { useHookStore } from '../../hook-store/store';

// import * as bucketObjectUtils from '../../utils/bucket-object-util';
// import * as fileMoveUtils from '../../utils/file-move-util';

// import { BACKEND_URL } from '../../App';
// import { marks } from '../../../utils/marks';

import './IllustrationGenerator.css';
// import classes from './BgRemoveUpload.module.css';

function IllustrationGeneratorUpload(props) {

  const { 
    outputFile,
    setShowUploadModal,
  } = props;

  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  const [bgRemoveFile, setBgRemoveFile] = useState();

  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  let uploadImageBody;

  uploadImageBody = (
    <div>
      <Backdrop 
        zIndex={'95'}
        onCancel={() => { 
          setShowUploadModal(false);
        }}
      />
      <Modal
        onClose={() => {
          setShowUploadModal(false);
        }}
      >
        <div>
          <div>
            Upload image to Kura Image Photo
          </div>
        </div>
        <div>
          <div className='illustGeneratorSection'>
            {outputFile && (
              <div>
                <img className='illustGeneratorImage'
                  src={URL.createObjectURL(outputFile)}
                />
              </div>
            )}
          </div>
          <div className='illustGeneratorSection'>
            <FilesUploadAction 
              uploadFiles={[outputFile]}
              uploadApp={'illustrationGenerator'}
            />
          </div>
        </div>
      </Modal>
    </div>
  )


  
  return (
    <Fragment>
      {uploadImageBody}
    </Fragment>
  );
}

export default IllustrationGeneratorUpload;
