import React, { Fragment, useEffect, useState } from "react";
import { isExpired, decodeToken } from "react-jwt";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { useHookStore } from "../../hook-store/store";
import { logoutHandler } from "../../utils/user/user";

// import { BACKEND_URL } from "../../App";
import { IMAGEPHOTO_BACKENDURL } from "../../lib/api";

import classes from './GetLoginUsers.module.css';

const GetLoginUsers = (props) => {
    const { } = props;

    const [t] = useTranslation('translation');

    const [store, dispatch] = useHookStore();
    // const isAuth = store.uiStore.isAuth;

    const [validLsUuid, setValidLsUuid] = useState(false);
    const [expiredTokenInfo, setExpiredTokenInfo] = useState();
    // const [stateLoginUserList, setStateLoginUserList] = useState();

    // const lsLoginUserList = localStorage.getItem('loginUserList');
    
    useEffect(() => {
        const lsLoginUserList = localStorage.getItem('loginUserList');
        const lsEmail = localStorage.getItem('email');
        const lsUuid = localStorage.getItem('lsUuid');

        if (lsLoginUserList) {
            const parsedList = JSON.parse(lsLoginUserList);

            for (const user of parsedList) {
                // console.log('user', user);
                getLoginUsersHandler(user.email, lsUuid);
            }
        }

        if (!lsLoginUserList) {
            if (lsEmail && lsUuid) {
                getLoginUsersHandler(lsEmail, lsUuid);
            }
        }

        if (lsEmail && lsUuid) {
            getLoginUsersHandler(lsEmail, lsUuid);
        }

    },[localStorage.getItem('email')]);

    useEffect(() => {

        const lsUuid = localStorage.getItem('lsUuid');

        if (!lsUuid) {
            const uuid = uuidv4();
            localStorage.setItem('lsUuid', uuid);
        } 
    },[]);


  const postGetUserLastLoginTokenHandler = async (email, lsUuid) => {
    try {
        const url = IMAGEPHOTO_BACKENDURL + `/auth/user-last-login-token`
        // const result = await fetch(url);
        const result = await fetch(url, {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + 'token',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                lsUuid: lsUuid,
            })
          });

        const resData = await result.json();

        console.log(result, resData);

        // if (resData.data && resData.data.errorType === 'lsUuid-not-found') {

        // }

        if (result.ok) {
            const loginUser = {
                email: email,
                lastLoginToken: resData.data,
                // isTokenExpired: isExpired(resData.data),
            }
            
            setValidLsUuid(true);

            return loginUser;
        }

    } catch(err) {
        console.log(err);
        // throw err;
    }
  };


  const getLoginUsersHandler = async (email, lsUuid) => {
    try {
        const loginUserList = localStorage.getItem('loginUserList');
        
        if (!email || !lsUuid) {
            throw new Error('lsEmail and lsUuid are required');
        }

        if (!loginUserList) {
            const loginUserData = await postGetUserLastLoginTokenHandler(email, lsUuid);
            
            if (loginUserData) {
                localStorage.setItem('loginUserList', JSON.stringify([loginUserData]));
                // setStateLoginUserList([loginUserData]);
            }
      
        } 
        else {
            const parsedList = JSON.parse(loginUserList);
            
            console.log('parsedList', parsedList);

            const isInList = parsedList.find(userData => {
                return userData.email === email;
            });

            console.log('isInList', isInList, email);

            if (!isInList) {
                const loginUserData = await postGetUserLastLoginTokenHandler(email, lsUuid);

                console.log('loginUserData', loginUserData);
                if (loginUserData) {
                    const newList = parsedList.concat(loginUserData);
                    // console.log('loginUserData newList', newList);
                    localStorage.setItem('loginUserList', JSON.stringify(newList));
                    // setStateLoginUserList(newList);
                }
            }

            if (isInList) {
                const loginUserData = await postGetUserLastLoginTokenHandler(email, lsUuid);

                // console.log('loginUserData', loginUserData)
                if (loginUserData) {
                    const userData = parsedList.find(user => {
                        return user.email === loginUserData.email;
                    });

                    // console.log('loginUserData userData', userData);    
                    // console.log('loginUserData userData2', userData.lastLoginToken === loginUserData.lastLoginToken);

                    if (userData.lastLoginToken !== loginUserData.lastLoginToken) {
                        // console.log('loginUserData userData3')
                        const withoutUserList = parsedList.filter(userData => {
                            return userData.email !== isInList.email;
                        });
                        
                        // console.log('loginUserData withoutUserList', withoutUserList);
                        const newList = withoutUserList.concat(loginUserData);
                        // console.log('loginUserData newList', newList);
                        localStorage.setItem('loginUserList', JSON.stringify(newList));
                    }



                    // setStateLoginUserList(newList);
                }
            }
      

        }
    
    } catch(err) {
        console.log(err);
    }
  };


  const changeUser = (email) => {
    const lsLoginUserList = localStorage.getItem('loginUserList');
    const userInfo = JSON.parse(lsLoginUserList).find(userData => {
        return userData.email === email;
    });

    if (userInfo && !isExpired(userInfo.lastLoginToken)) {
        const decoded = decodeToken(userInfo.lastLoginToken);

        localStorage.setItem('email', decoded.email);
        localStorage.setItem('tokenForCasTGT', userInfo.lastLoginToken);
        localStorage.setItem('token', userInfo.lastLoginToken);
        localStorage.setItem('userId', decoded.userId);
        
        const tokenExpire = new Date(Number(decoded.exp) * 1000);
        // console.log('tokenExpire', tokenExpire);
        localStorage.setItem('TGTexp', decoded.exp);
        localStorage.setItem('expiryDate', tokenExpire);
        localStorage.removeItem('name');

        window.location.reload();
    }

    if (userInfo && isExpired(userInfo.lastLoginToken)) {
        //// token expire login is required.
        // console.log('expired');
        setExpiredTokenInfo({ email: userInfo.email, tokenExpire: true });
    
        if (userInfo.email === localStorage.getItem('email')) {
            dispatch('SET_ISAUTH', false);
            logoutHandler();
        }
    }

  };


    let loginUserListBody;
    const lsLoginUserList = localStorage.getItem('loginUserList');

    if (lsLoginUserList && JSON.parse(lsLoginUserList).length > 0) {
    
        const sorted = _.orderBy(JSON.parse(lsLoginUserList), ['email'],['asc']);
        // console.log('sorted', sorted);
        loginUserListBody = (
            <div>

                {localStorage.getItem('email') && (
                    <div>
                        <div className={classes.loginUsersUser}>
                            <strong>{localStorage.getItem('email')}</strong>
                        </div>
                        {/* <div className={classes.loginUsersSeparator} /> */}
                    </div>
                )}

                <ul>
                    {sorted.map(user => {

                        let isTokenOk = false;

                        if (!isExpired(user.lastLoginToken)) {
                            isTokenOk = true;
                        }

                        if (expiredTokenInfo && expiredTokenInfo.email === user.email) {
                            isTokenOk = false;
                        }

                        // console.log('isTokenOk', isTokenOk);

                        return (
                        <li key={user.email}>
                            <div className={classes.loginUsersSeparator} />
                            {/* <div>valid-lsUuid: {validLsUuid && 'valid-lsUuid'}</div> */}
                            <div className={classes.loginUsersUser}>
                                <div className={classes.loginUsersEmail}>
                                    {user.email}
                                </div>

                                {isTokenOk && (
                                    <div className={classes.loginUsersSelect}>
                                        <button className={classes.loginUsersSelectButton}
                                            onClick={() => { changeUser(user.email); }}
                                        >
                                            {t('getLoginUser.01', 'Use This')}
                                        </button>
                                    </div>
                                )}

                                {!isTokenOk && (
                                    <div className={classes.loginUsersSelect}>
                                        {t('getLoginUser.02', 'Login required')}
                                    </div>      
                                )}
                            </div>

                            {/* <div>
                                last-login-token: {user.lastLoginToken.slice(1,5)}.....{user.lastLoginToken.slice(-5)}
                            </div> */}

                            {/* <div>decoded: {JSON.stringify(decodeToken(user.lastLoginToken))}</div> */}
                        </li>);
                    
                    })}
                </ul>
            </div>
        );
  }


  return <Fragment>
    {/* <div>getLoginUsers-component</div> */}
    <div>{loginUserListBody}</div>
  </Fragment>;
};

export default GetLoginUsers;




// import React, { Fragment, useEffect, useState } from "react";
// import { isExpired, decodeToken } from "react-jwt";
// import { useTranslation } from 'react-i18next';
// import { v4 as uuidv4 } from 'uuid';
// import _ from 'lodash';

// import { useStore } from "../../hook-store/store";
// import { logoutHandler } from "../../utils/user/user";

// import { BACKEND_URL, loginPageLink } from "../../App";

// import { marks } from "../../utils/marks";
// import classes from './GetLoginUsers.module.css';

// const GetLoginUsers = (props) => {
//     const { } = props;

//     const [t] = useTranslation('translation');

//     const [store, dispatch] = useStore();
//     const { 
//         isAuth,
//         userNameData,
//     } = store.uiStore;

//     const [validLsUuid, setValidLsUuid] = useState(false);
//     const [expiredTokenInfo, setExpiredTokenInfo] = useState();
//     const [tokenNameDataList, setTokenNameDataList] = useState([]);

//     // const [stateLoginUserList, setStateLoginUserList] = useState();

//     // const lsLoginUserList = localStorage.getItem('loginUserList');
    
//     useEffect(() => {
//         const lsLoginUserList = localStorage.getItem('loginUserList');
//         const lsEmail = localStorage.getItem('email');
//         const lsUuid = localStorage.getItem('lsUuid');

//         if (lsLoginUserList) {
//             const parsedList = JSON.parse(lsLoginUserList);

//             for (const user of parsedList) {
//                 // console.log('user', user);
//                 getLoginUsersHandler(user.email, lsUuid);
//             }
//         }

//         if (!lsLoginUserList) {
//             if (lsEmail && lsUuid) {
//                 getLoginUsersHandler(lsEmail, lsUuid);
//             }
//         }

//         if (lsEmail && lsUuid) {
//             getLoginUsersHandler(lsEmail, lsUuid);
//         }

//     },[localStorage.getItem('email')]);

//     useEffect(() => {
//         const lsUuid = localStorage.getItem('lsUuid');

//         if (!lsUuid) {
//             const uuid = uuidv4();
//             localStorage.setItem('lsUuid', uuid);
//         } 
//     },[]);


//     useEffect(() => {
//         if (isAuth && localStorage.getItem('loginUserList')) {
//             postGetTokenUserNameDataListHandler(localStorage.getItem('loginUserList'));
//         }
//     },[isAuth]);

//     //// update nameDataList when userNameData is updated
//     useEffect(() => {
//         if (isAuth && localStorage.getItem('loginUserList') && 
//             userNameData && tokenNameDataList.length > 0
//         ) {
//             postGetTokenUserNameDataListHandler(localStorage.getItem('loginUserList'));
//         }
//     },[userNameData]);

//   const postGetUserLastLoginTokenHandler = async (email, lsUuid) => {
//     try {
//         const url = BACKEND_URL + `/auth/user-last-login-token`
//         // const result = await fetch(url);
//         const result = await fetch(url, {
//             method: 'POST',
//             headers: {
//               Authorization: 'Bearer ' + 'token',
//               'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({
//                 email: email,
//                 lsUuid: lsUuid,
//             })
//           });

//         const resData = await result.json();

//         console.log(result, resData);

//         // if (resData.data && resData.data.errorType === 'lsUuid-not-found') {

//         // }

//         if (result.ok) {
//             const loginUser = {
//                 email: email,
//                 lastLoginToken: resData.data,
//                 // isTokenExpired: isExpired(resData.data),
//             }
            
//             setValidLsUuid(true);

//             return loginUser;
//         }

//     } catch(err) {
//         console.log(err);
//         // throw err;
//     }
//   };


//   const getLoginUsersHandler = async (email, lsUuid) => {
//     try {
//         const loginUserList = localStorage.getItem('loginUserList');
        
//         if (!email || !lsUuid) {
//             throw new Error('lsEmail and lsUuid are required');
//         }

//         if (!loginUserList) {
//             const loginUserData = await postGetUserLastLoginTokenHandler(email, lsUuid);
            
//             if (loginUserData) {
//                 localStorage.setItem('loginUserList', JSON.stringify([loginUserData]));
//                 // setStateLoginUserList([loginUserData]);
//             }
      
//         } 
//         else {
//             const parsedList = JSON.parse(loginUserList);
            
//             console.log('parsedList', parsedList);

//             const isInList = parsedList.find(userData => {
//                 return userData.email === email;
//             });

//             console.log('isInList', isInList, email);

//             if (!isInList) {
//                 const loginUserData = await postGetUserLastLoginTokenHandler(email, lsUuid);

//                 console.log('loginUserData', loginUserData);
//                 if (loginUserData) {
//                     const newList = parsedList.concat(loginUserData);
//                     // console.log('loginUserData newList', newList);
//                     localStorage.setItem('loginUserList', JSON.stringify(newList));
//                     // setStateLoginUserList(newList);
//                 }
//             }

//             if (isInList) {
//                 const loginUserData = await postGetUserLastLoginTokenHandler(email, lsUuid);

//                 // console.log('loginUserData', loginUserData)
//                 if (loginUserData) {
//                     const userData = parsedList.find(user => {
//                         return user.email === loginUserData.email;
//                     });

//                     // console.log('loginUserData userData', userData);    
//                     // console.log('loginUserData userData2', userData.lastLoginToken === loginUserData.lastLoginToken);

//                     if (userData.lastLoginToken !== loginUserData.lastLoginToken) {
//                         // console.log('loginUserData userData3')
//                         const withoutUserList = parsedList.filter(userData => {
//                             return userData.email !== isInList.email;
//                         });
                        
//                         // console.log('loginUserData withoutUserList', withoutUserList);
//                         const newList = withoutUserList.concat(loginUserData);
//                         // console.log('loginUserData newList', newList);
//                         localStorage.setItem('loginUserList', JSON.stringify(newList));
//                     }



//                     // setStateLoginUserList(newList);
//                 }
//             }
      

//         }
    
//     } catch(err) {
//         console.log(err);
//     }
//   };


//   const changeUser = (email) => {
      
//     if (email === localStorage.getItem('email')) {
//         return;
//     }

//     const lsLoginUserList = localStorage.getItem('loginUserList');
//     const userInfo = JSON.parse(lsLoginUserList).find(userData => {
//         return userData.email === email;
//     });


//     if (userInfo && !isExpired(userInfo.lastLoginToken)) {
//         const decoded = decodeToken(userInfo.lastLoginToken);

//         localStorage.setItem('email', decoded.email);
//         localStorage.setItem('tokenForCasTGT', userInfo.lastLoginToken);
//         localStorage.setItem('token', userInfo.lastLoginToken);
//         localStorage.setItem('userId', decoded.userId);
        
//         const tokenExpire = new Date(Number(decoded.exp) * 1000);
//         // console.log('tokenExpire', tokenExpire);
//         localStorage.setItem('TGTexp', decoded.exp);
//         localStorage.setItem('expiryDate', tokenExpire);
//         localStorage.removeItem('name');

//         window.location.reload();
//     }

//     if (userInfo && isExpired(userInfo.lastLoginToken)) {
//         //// token expire login is required.
//         // console.log('expired');
//         setExpiredTokenInfo({ email: userInfo.email, tokenExpire: true });
    
//         if (userInfo.email === localStorage.getItem('email')) {
//             dispatch('SET_ISAUTH', false);
//             logoutHandler();
//         }
//     }

//   };


//   const postGetTokenUserNameDataListHandler = async (lsLoginUserList) => {
//     try {
//         if (!lsLoginUserList || JSON.parse(lsLoginUserList).length === 0) {
//             return;
//         }

//         const tokens = [];

//         for (const loginUser of JSON.parse(lsLoginUserList)) {
//             if (loginUser.lastLoginToken) {
//                 tokens.push(loginUser.lastLoginToken);
//             }
//         }

//         const url = BACKEND_URL + `/auth/token-user-name-data-list`
//         // const result = await fetch(url);
//         const result = await fetch(url, {
//             method: 'POST',
//             headers: {
//               Authorization: 'Bearer ' + localStorage.getItem('token'),
//               'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({
//                 tokens: tokens,
//             })
//           });

//         const resData = await result.json();

//         console.log(result, resData);
//         if (resData.data?.length > 0) {
//             setTokenNameDataList(resData.data);
//         }

//     } catch(err) {
//         console.log(err);
//         // throw err;
//     }
//   };


//     let loginUserListBody;

//     if (localStorage.getItem('email')) {
//         loginUserListBody = (
//             <div>
//                 <div className={classes.loginUsersUser}>
//                     <strong>{localStorage.getItem('email')}</strong>
//                 </div>
//                 {/* <div className={classes.loginUsersSeparator} /> */}
//             </div>
//         );
//     }

//     const lsLoginUserList = localStorage.getItem('loginUserList');

//     if (lsLoginUserList && JSON.parse(lsLoginUserList).length > 0) {
    
//         const sorted = _.orderBy(JSON.parse(lsLoginUserList), ['email'],['asc']);
//         // console.log('sorted', sorted);
//         loginUserListBody = (
//             <div>
//                 {localStorage.getItem('email') && (
//                     <div>
//                         <div className={classes.loginUsersEmail}>
//                             <strong>{localStorage.getItem('email')}</strong>
//                         </div>
//                         {/* <div className={classes.loginUsersSeparator} /> */}
//                     </div>
//                 )}
//                 <ul>
//                     {sorted.map(user => {

//                         let isTokenOk = false;

//                         const decodedToken = decodeToken(user.lastLoginToken);
//                         const nameData = tokenNameDataList.find(element => {
//                             return decodedToken.userId === element.userId;
//                         });

//                         let userImageEl;

//                         if (nameData?.imageUrl) {
//                             userImageEl = (
//                                 <img style={{height: "2.5rem", width:"2.5rem", objectFit:"cover"}}
//                                     src={nameData.imageUrl}
//                                 />
//                             );
//                         } else {
//                             userImageEl = (
//                                 <span style={{fontSize:"2.5rem"}}>
//                                    {marks.userMrak}
//                                 </span>
//                             );
//                         }

//                         if (!isExpired(user.lastLoginToken)) {
//                             isTokenOk = true;
//                         }

//                         if (expiredTokenInfo && expiredTokenInfo.email === user.email) {
//                             isTokenOk = false;
//                         }

//                         // console.log('isTokenOk', isTokenOk);

//                         return (
//                         <li key={user.email}>
//                             <div className={classes.loginUsersSeparator} />
//                             {/* <div>valid-lsUuid: {validLsUuid && 'valid-lsUuid'}</div> */}
//                             <div className={classes.loginUsersUser}>
//                                 <div>
//                                     {userImageEl}
//                                 </div>
//                                 <div>
//                                     {/* {userImageEl}  */}
//                                     {user.email}
//                                 </div>
//                             </div>

//                             <div>
//                                 {isTokenOk && (
//                                     <div className={classes.loginUsersSelect}>
//                                         <button className={classes.loginUsersSelectButton}
//                                             onClick={() => { changeUser(user.email); }}
//                                         >
//                                             {t('getLoginUser.01', 'Use This')}
//                                         </button>
//                                     </div>
//                                 )}

//                                 {!isTokenOk && (
//                                     <div className={classes.loginUsersSelect}>
//                                         {t('getLoginUser.02', 'Login required')}
//                                     </div>      
//                                 )}

//                             </div>

//                             {/* <div>
//                                 last-login-token: {user.lastLoginToken.slice(1,5)}.....{user.lastLoginToken.slice(-5)}
//                             </div> */}

//                             {/* <div>decoded: {JSON.stringify(decodeToken(user.lastLoginToken))}</div> */}
//                         </li>);
                    
//                     })}
//                 </ul>
//             </div>
//         );
//   }


//   return <Fragment>
//     {/* <div>getLoginUsers-component</div> */}
//     <div>
//     {loginUserListBody}
//     </div>
//   </Fragment>;
// };

// export default GetLoginUsers;
