import { useState, useEffect, Fragment } from 'react';
// import _, { isLength } from 'lodash';
// import axios from 'axios';
import Backdrop from '../../Backdrop/Backdrop';
import Modal from '../../Modal/Modal';

import BgRemoveUploadAction from './BgRemoveUploadAction';
// import BucketObjectList from './BucketObjectList';
import { useHookStore } from '../../../hook-store/store';

// import * as bucketObjectUtils from '../../utils/bucket-object-util';
// import * as fileMoveUtils from '../../utils/file-move-util';

// import { BACKEND_URL } from '../../App';
// import { marks } from '../../../utils/marks';
import classes from './BgRemoveUpload.module.css';

function BgRemoveUpload(props) {

  const { 
    canvasImg64Data,
    setShowUploadModal,
  } = props;

  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  const [bgRemoveFile, setBgRemoveFile] = useState();

  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  useEffect(() => {
    if (canvasImg64Data) {
      base64ToFileHandler(canvasImg64Data);
    }
  },[canvasImg64Data]);

  const base64ToFileHandler = async (b64) => {
    try {
      const fileData = b64.split(";")[0];
      const fileType = fileData.split(":")[1];
      const fileName = `bgremove-${Date.now()}.${fileType.split("/")[1]}`;

      const result = await fetch(b64);
      const resData = await result.blob();
      const file = new File([resData], fileName, { type: fileType });
    //   console.log(file);

      if (file) {
        setBgRemoveFile(file);
      }

      return file;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  let uploadImageBody;

  uploadImageBody = (
    <div>
      <Backdrop 
        zIndex={'95'}
        onCancel={() => { 
          setShowUploadModal(false);
        }}
      />
      <Modal
        onClose={() => {
          setShowUploadModal(false);
        }}
      >
        <div className={classes.bgRemoveUploadSection}>
          <div className={classes.bgRemoveUploadTitle}>
            Upload image to Kura Image Photo
          </div>
        </div>
        <div className={classes.bgRemoveAction}>
          <BgRemoveUploadAction
            bgRemoveFile={bgRemoveFile}
          />
        </div>
      </Modal>
    </div>
  )


  
  return (
    <Fragment>
      {uploadImageBody}
    </Fragment>
  );
}

export default BgRemoveUpload;
