// import { PlayIcon } from "@radix-ui/react-icons"
import { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom";
// import { IconButton, ImageUploadButton } from "../components/ui/button"
// import Shortcuts from "../components/Shortcuts"
import { useImage } from "../../hooks/useImage"

// import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
// import PromptInput from "./PromptInput"
// import { RotateCw, Image, Upload } from "lucide-react"
// import FileManager from "./FileManager"
// import { getMediaFile } from "../lib/api"
import { useStore } from "../../lib/states"
import { srcToFile } from "../../lib/utils";
// import SettingsDialog from "./Settings"
// import { cn, fileToImage } from "../lib/utils"
// import Coffee from "./Coffee"
import { useToast } from "../ui/use-toast"

import Backdrop from "../Backdrop/Backdrop";
import Modal from "../Modal/Modal";
import { useHookStore } from "../../hook-store/store"
import { resizeImageFile } from '../../utils/image/image-utils';

import { kuraImagePhotoUrl } from "../../lib/api";

import { marks } from "../../utils/marks";

const FileControl = () => {
  const [
    file,
    customMask,
    isInpainting,
    serverConfig,
    runMannually,
    enableUploadMask,
    model,
    setFile,
    setCustomFile,
    runInpainting,
    showPrevMask,
    hidePrevMask,
    imageHeight,
    imageWidth,
    updateSettings,
    settings,
    editorState,
  ] = useStore((state) => [
    state.file,
    state.customMask,
    state.isInpainting,
    state.serverConfig,
    state.runMannually(),
    state.settings.enableUploadMask,
    state.settings.model,
    state.setFile,
    state.setCustomFile,
    state.runInpainting,
    state.showPrevMask,
    state.hidePrevMask,
    state.imageHeight,
    state.imageWidth,
    state.updateSettings,
    state.settings,
    state.editorState,
  ])

  const renders = editorState.renders;

  const { toast } = useToast()
  const [maskImage, maskImageLoaded] = useImage(customMask)
  const [openMaskPopover, setOpenMaskPopover] = useState(false)

  const [showLargeSizeModal, setShowLargeSizeModal] = useState(false);

  const [hStore, hDispatch] = useHookStore();
  // const { 
  //   showToggleNav, 
  //   // gLoading,
  //   // isAuth,
  //  } = (hStore as any).uiStore;

   useEffect(() => {
    if (imageHeight > 1024 && imageWidth > 1024) {

      setShowLargeSizeModal(true);

      // toast({
      //   variant: "destructive",
      //   description: `File size is too big. Limit size is 1024 x 1024. image size: ${imageWidth} x ${imageHeight}`,
      // });

      // setTimeout(() => {
      //   window.location.reload();
      // },5000);
    }
   }, [imageHeight, imageWidth]);


  //  useEffect(() => {
  //   if (imageHeight > 512 || imageWidth > 512) {

  //     if (!settings.showCropper) {
  //       updateSettings({ showCropper: true })
  //     }
  //   }
  //  }, [imageHeight, imageWidth, settings]);

   useEffect(() => {
      updateSettings({ showCropper: false })
   }, [imageHeight, imageWidth]);


   useEffect(() => {
    if (renders.length > 0 && file) {
      createCurrentInpaintFile(file);
    }

    if (renders.length === 0) {
      hDispatch('SET_CURRENTINPAINTFILE', null);
    }
   },[renders.length])


  const closeLargeSizeModalHandler = () => {
    setShowLargeSizeModal(false);
    window.location.reload();
  };

  const largeImegeResizeHandler = async (file) => {
    try {

      let resFile;

      // if (imageWidth > imageHeight) {
      //   resFile = await resizeImageFile(
      //     file,
      //     'file',
      //     file.type.split('/')[1],
      //     imageWidth,
      //     1024,
      //   );
      // } else {
      //   resFile = await resizeImageFile(
      //     file,
      //     'file',
      //     file.type.split('/')[1],
      //     1024,
      //     imageHeight,
      //   );
      // }
      resFile = await resizeImageFile(
        file,
        'file',
        file.type.split('/')[1],
        1024,
        1024,
      );

      console.log(resFile);

      if (resFile) {
        setFile(resFile);
      } else {
        throw new Error('resize failed');
      }

      setShowLargeSizeModal(false);

    } catch(err) {
      console.log(err);
      closeLargeSizeModalHandler();
      throw err;
    }
  }

  const createCurrentInpaintFile = async (editorFile) => {
    let targetFile;
    const ext = editorFile.type.split('/')[1];

    if (renders.length > 0) {
      const lastRender = renders[renders.length - 1]
      targetFile = await srcToFile(
        lastRender.currentSrc, 
        `inpaint-${Date.now()}.${ext}`, 
        editorFile.type
      );
    }

    // console.log(targetFile);

    if (targetFile) {
      hDispatch('SET_CURRENTINPAINTFILE',targetFile);
    }

    return targetFile
  }
  return (
    <Fragment>
      {showLargeSizeModal && (
        <div>
          <Backdrop 
            zIndex={'95'}
            onCancel={closeLargeSizeModalHandler}
          />
          <Modal
            onClose={closeLargeSizeModalHandler}
          >
            <div>
              <div>
                File is too big. Width or height shold be less than 1024px.
              </div>
              <div>
                Image size: {imageWidth}px x {imageHeight}px.
              </div>
              <div>
                <button className="iopaintBtnBase"
                  onClick={() => { largeImegeResizeHandler(file) }}
                >
                  Resize image to smaller size
                </button>
                {' '}
                <span>
                  or resize images at 
                  {' '}
                  <Link to={`${kuraImagePhotoUrl}/image-resizer`} 
                    target="_blank"  rel="noreferrer noopener"
                    onClick={() => {}}
                  >
                    Image Resizer {marks.externalLinkMark}
                  </Link>
                </span>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </Fragment>
  )
}

export default FileControl
