// import _ from 'lodash';
// import { forTranslation } from './forTanslation';

// import { languages } from './config';
// const { languages } = require('./config');
import { languages } from './config';

// console.log(forTranslation);

// const newArray = [];
// for (const key in forTranslation) {

//   const lanCode = forTranslation[key].CODE;
//   // console.log(lanCode);
//   // console.log(key, temp[key]);
//   newArray.push(
//     { 
//       lCode: lanCode,
//       data: { translation: forTranslation[key] }
//     }
//   )
// }
// console.log(newArray);

// console.log(_.keyBy(temp, 'CODE'));

// export const resources = 
//   _.chain(newArray)
//     .keyBy('lCode')
//     .mapValues('data')
//     .value();

// console.log(resources);


// const lan2 = languages;

// console.log(languages);


const trDataList = [];
// for (let i=0; i<languages.length; i++) {
// 	const data = await import('./locales/' + languages[i] + '/common.json')
// 	trDataList.push(data);
// }

for (const [index, value] of languages.entries()) {
	const data = await import('./locales/' + languages[index] + '/common.json')
	trDataList.push(data);
}

console.log(trDataList);


export const resources = Object.assign(
	{},
	...Object.keys(languages).map((index) => {
		return {
			[languages[index]]: {
				// translations: require('../locales/' + languages[index] + '/translation.json'),
				// translation: require('./locales/' + languages[index] + '/common.json'),
				translation: trDataList[index]
				// translations: require('./locales/' + 'af' + '/common.json'),
			},
		};
	}),
);

console.log('language resources', resources);




// const createTranslationResources = async (languages) => {

// 	const trDataList = [];
// 	// for (let i=0; i<languages.length; i++) {
// 	// 	const data = await import('./locales/' + languages[i] + '/common.json')
// 	// 	trDataList.push(data);
// 	// }

// 	for (const [index, value] of languages.entries()) {
// 		const data = await import('./locales/' + languages[index] + '/common.json')
// 		trDataList.push(data);
// 	}

// 	console.log(trDataList);


// 	const resources = Object.assign(
// 		{},
// 		...Object.keys(languages).map((index) => {
// 			return {
// 				[languages[index]]: {
// 					// translations: require('../locales/' + languages[index] + '/translation.json'),
// 					// translation: require('./locales/' + languages[index] + '/common.json'),
// 					translation: trDataList[index]
// 					// translations: require('./locales/' + 'af' + '/common.json'),
// 				},
// 			};
// 		}),
// 	);

// 	return resources;
// }

// export let resources;

// resources = await createTranslationResources(languages);

// console.log(resources);