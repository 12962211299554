import React, { Fragment, useEffect } from 'react';

// import openSocket from 'socket.io-client';
// import { io } from "socket.io-client";
import { io } from 'socket.io-client';

// import { useStore } from '../../hook-store/store';
import { useHookStore } from '../../hook-store/store';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util'
// import { getPrefixFromKey } from '../../../utils/bucket/bucket-object-util';


import { IMAGEPHOTO_BACKENDURL } from '../../lib/api';

// import classes from './PostSelect.module.css';


function IllustrationGeneratorSocket(props) {
  // const { } = props;

  const [hStore, hDispatch] = useHookStore();
  // const { bucketName, currentPrefix } = store.bucketObjectStore;
  // const { isAuth } = hStore.uiStore;

  useEffect(() => {

      const socket = io.connect(IMAGEPHOTO_BACKENDURL, {
        // reconnection: true,
        extraHeaders: {
          // "my-custom-header": "1234",
          "header-client": 'illustration-generator-frontend',
        }
      });

      // console.log('before socket.on connect')
      socket.on("connect", () => {
        console.log('connect socket.id', socket.id);
      });

      socket.on("upscaler_request", (data) => {
        console.log('upscaler_request data', data);
        hDispatch('SET_UPSCALESERVERPROCESSING', true);
      });

      socket.on("upscaler_request_error", (data) => {
        console.log('upscaler_request data', data);
        hDispatch('SET_UPSCALESERVERPROCESSING', false);
      });

      socket.on("upscaler_processing", (data) => {
        console.log('upscaler_processing data', data);
        hDispatch('SET_UPSCALESERVERPROCESSING', true);
      });

      socket.on("upscaler_processing_start", (data) => {
        console.log('upscaler_processing_start data', data);
        hDispatch('SET_UPSCALESERVERPROCESSING', true);
      });

      socket.on("upscaler_processing_end", (data) => {
        console.log('upscaler_processing_end data', data);
        hDispatch('SET_UPSCALESERVERPROCESSING', false);
      });

      // socket.on("upscaler_processing_error", (data) => {
      //   console.log('upscaler_processing_error data', data);
      //   hDispatch('SET_UPSCALESERVERPROCESSING', false);
      // });


      return () => {
        socket.off('connect')
        socket.off('disconnect')
        // socket.off('progress')
        // socket.off('finish')
  
        socket.off('upscaler_request')
        socket.off('upscaler_request_error')
        socket.off('upscaler_processing')
        socket.off('upscaler_processing_start')
        socket.off('upscaler_processing_end')
        // socket.off('upscaler_processing_error')
        // socket.off('run_params')
      }
  },[]);


 



 

  return (
    <Fragment></Fragment>
  );
}

export default IllustrationGeneratorSocket;