import React from 'react';
// import { useEffect, useState } from 'react';

import ToggleNavItems from '../ToggleNavItems/ToggleNavItems';

import { useHookStore } from '../../../../hook-store/store';

import './ToggleNav.css';

const ToggleNav = props => {
  // console.log('mobileNavigation-props', props)
  // const [t] = useTranslation('translation');

  const [store, dispatch] = useHookStore();

  const showToggleNav = store.uiStore.showToggleNav;
  const gLoading = store.uiStore.gLoading;
  // const [userData, setUserData] = useState('');
  // const [darkMode, setDarkMode] = useState();

  // useEffect(() => {
  //   dispatch('SET_SHOWTOGGLENAV', true);

  //   setTimeout(() => {
  //     dispatch('SET_SHOWTOGGLENAV', false)
  //   }, 1000*10);
  // },[]); 

  return (
    <nav className={['mobile-nav', showToggleNav ? 'open' : ''].join(' ')}
      // onClick={() => { dispatch('SET_SHOWTOGGLENAV', false);}}
    >
      {/* <div className="navigation-item">{props.name}</div> */}
      <div>
        <ToggleNavItems />
        {/* {props.isAuth ? 
          <span>
            {userData.imageUrl ?
              <img className="userImage" src={BASE_URL + '/' + userData.imageUrl} alt="" height="25" />
              <span className="mobile-nav__userImageContainer">
                <img className="userImage" src={userData.imageUrl} alt="" height="25" />
                <span className="mobile-nav__name">{userData.name}</span>
              </span>
              : <span className="mobile-nav__name">{userData.name}</span>
            }
          </span>
          : <NavLink to="/login"><span className="name">
            Login
            {t('nav.text2')}
          </span></NavLink>
          } */}

        {/* <ul
          className={['mobile-nav__items', props.mobile ? 'mobile' : ''].join(' ')}
        >
          <NavigationItems
            mobile
            onChoose={props.onChooseItem}
            isAuth={props.isAuth}
            onLogout={props.onLogout}
          />
        </ul> */}
        
        {/* <div className="mobile-nav__separator" />

        <div className={['navigation-item', props.mobile ? 'mobile' : ''].join(' ')}> 
          <DarkModeToggle 
            setDarkMode={setDarkMode}
          />
        </div> */}
      </div>
      
      {/* <div>
        <div className="mobile-nav__footer">
          <NavFooter 
            onChoose={props.onChooseItem}
          />
        </div>
      </div> */}
      
    </nav>
  );
}

export default ToggleNav;
