import * as React from "react"
import { Fragment, useEffect, useState } from "react"
import io from "socket.io-client"
import { Progress } from "./ui/progress"
import { useStore } from "../lib/states"

import SmallModal from "./Modal/SmallModal";
import { useHookStore } from "../hook-store/store";
import { marks } from "../utils/marks";
// export const API_ENDPOINT = import.meta.env.DEV
//   ? import.meta.env.VITE_BACKEND
//   : ""

//// for dev
//// export const API_ENDPOINT = 'http://localhost:8080';
//// export const API_ENDPOINT = 'http://localhost:4002';
//// export const API_ENDPOINT = 'http://localhost:4010';
// export const API_ENDPOINT = 'http://localhost:4009';


//// for test deploy
// export const API_ENDPOINT = 'https://dokmp19-ec.spaceeight.net';


//// for deploy
export const API_ENDPOINT = 'https://dokmp19-e.spaceeight.net';



// const socket = io(API_ENDPOINT)
const socket = io(API_ENDPOINT, {
  // path: '/ws/socket.io/',
  // transports: ['websocket', 'polling'],
  extraHeaders: {
    "my-custom-header": "1234",
    "header-client": 'iopaint-frontend',
  }
})

const DiffusionProgress = () => {
  const [settings, isInpainting, isSD] = useStore((state) => [
    state.settings,
    state.isInpainting,
    state.isSD(),
  ])

  // const storeData = useStore((state) => {
  //   console.log(state)
  //   console.log(state.isInpainting)
  // })

  // console.log('process isInpainting', isInpainting);

  const [isConnected, setIsConnected] = React.useState(false)
  const [step, setStep] = React.useState(0)

  const [isUserRunning, setIsUserRunning] = useState(false);

  const [hStore, hDispatch] = useHookStore();
  const { serverProcessing } = (hStore as any).iopaintStore;


  const progress = Math.min(Math.round((step / settings.sdSteps) * 100), 100)

  useEffect(() => {
    //// after user run finish add start disabled period
    if (!isInpainting && isUserRunning) {
      (hDispatch as any)('SET_SERVERPROCESSING', true);

      setTimeout(() => {
        setIsUserRunning(false);
        (hDispatch as any)('SET_SERVERPROCESSING', false);
      }, 1000*20)
    } else {
      setIsUserRunning(isInpainting);
    }
  },[isInpainting]);

  React.useEffect(() => {
    socket.on("connect", () => {
      console.log('connected')
      setIsConnected(true)

      // socket.emit('iopaint-frontend', { socketId: socket.id });
    })

    socket.on("disconnect", () => {
      setIsConnected(false)
    })

    socket.on("diffusion_progress", (data) => {
      if (data) {
        setStep(data.step + 1)
      }
    })

    socket.on("diffusion_finish", () => {
      setStep(0)
    })


    socket.on("processing", (data) => {
      // console.log("processing data", data);

      (hDispatch as any)('SET_SERVERPROCESSING', true);
      // (hDispatch as any)('SHOW_NOTIFICATION', {
      //   status: '',
      //   title: 'Server is busy...',
      //   message: '',
      // });
    });

    socket.on("processing_start", (data) => {
      console.log("processing_start data", data);

      (hDispatch as any)('SET_SERVERPROCESSING', true);
      // (hDispatch as any)('SHOW_NOTIFICATION', {
      //   status: '',
      //   title: 'Server is busy...',
      //   message: '',
      // });
    });

    socket.on("processing_error", (data) => {
      console.log("processing_error data", data);

      (hDispatch as any)('SET_SERVERPROCESSING', false);
      // (hDispatch as any)('CLEAR_NOTIFICATION');
    });

    socket.on("processing_finish", (data) => {
      console.log("processing_finish data", data);

      (hDispatch as any)('SET_SERVERPROCESSING', false);
      // (hDispatch as any)('CLEAR_NOTIFICATION');
    });


    //// get queue state
    socket.on("iopaint-queue-state", (data) => {
      console.log("iopaint-queue-state data", data);

    });

    return () => {
      socket.off("connect")
      socket.off("disconnect")
      socket.off("diffusion_progress")
      socket.off("diffusion_finish")

      socket.off("processing");
      socket.off("processing_start");
      socket.off("processing_error");
      socket.off("processing_finish");
      socket.off("iopaint-queue-state");
    }
  }, [])


  return (
    <Fragment>
      {/* <div
        className="z-10 fixed bg-background w-[220px] left-1/2 -translate-x-1/2 top-[68px] h-[32px] flex justify-center items-center gap-[18px] border-[1px] border-[solid] rounded-[14px] pl-[8px] pr-[8px]"
        style={{
          visibility: isConnected && isInpainting && isSD ? "visible" : "hidden",
        }}
      >
        <Progress value={progress} />
        <div className="w-[45px] flex justify-center font-nums">{progress}%</div>
      </div> */}
      {isInpainting && (
        <SmallModal
          onClose={() => {}}
          modalStyle={null}
          hideCloseButton={true}
        >
          <div style={{ textAlign: "center" }}>
            <div>Image processing</div>
            <div>
              {/* {step} / {settings.sdSteps}  */}
              {marks.spinner}
            </div>
          </div>
        </SmallModal>
      )}
    </Fragment>
  )
}

export default DiffusionProgress
