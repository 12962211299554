import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useHookStore } from '../../hook-store/store';

import { marks } from '../../utils/marks';
import './AboutIllustGenerator.css';

import IllustInputImage1 from '../../image/for-illustration-generator/illust-man-input.png';
import IllustImage1 from '../../image/for-illustration-generator/illust-man.jpeg';
import IllustInputImage2 from '../../image/for-illustration-generator/illustration-miami-input-h300.jpg';
import IllustImage2 from '../../image/for-illustration-generator/illustration-miami.jpg';
import IllustInputImage3 from '../../image/for-illustration-generator/illust-man2-input.jpg';
import IllustImage3 from '../../image/for-illustration-generator/illust-man2.jpg';
import IllustInputImage4 from '../../image/for-illustration-generator/illust-bird-input.png';
import IllustImage4 from '../../image/for-illustration-generator/illust-bird.jpeg';
import IllustInputImage5 from '../../image/for-illustration-generator/illust-mona-lisa-input.jpg';
import IllustImage5 from '../../image/for-illustration-generator/illust-mona-lisa.jpeg';
import IllustInputImage6 from '../../image/for-illustration-generator/illust-bridge-input-h350.jpg';
import IllustImage6 from '../../image/for-illustration-generator/illust-bridge.jpg';

import upscaleInputImage from '../../image/for-illustration-generator/upscale-anime-input-h250.png';
import UpscaleImage from '../../image/for-illustration-generator/upscale-illust2.jpg';

function AboutIllustGenerator(props) {
  const { } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  const [showMoreIllust, setShowMoreIllust] = useState(false);

  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  let illustAboutBody;

  illustAboutBody = (
    <div>
        <div>
          <div className='illustAboutTitle'>
            Illustration Generator & Illustration Upscaler
          </div>

          <div>
            <strong>
              Illustration Generator
            </strong>
          </div>

          <p>
            Illustration Generator {' '}
            {t('aboutIllustGenerator01', 'is a tool to generate a illustration image from a input image.')}
          </p>

          <p>
          {t('aboutIllustGenerator02', 'By using AI-technologies, input picture image can be converted into illustarion image.')}
          </p>
          <br/>
          
          <p>{t('aboutIllustGenerator03', 'Examples of Illustration Generation')}</p>
          <br/>

          <div className='illustAboutImageContainer'>
            <div>
              <img className='illustAboutImage'
                src={IllustInputImage1}
              />
              {t('aboutBgRemove03', 'input image')}
            </div>
            <div>
              <img className='illustAboutImage'
                src={IllustImage1}
              />
              {t('aboutIllustGenerator06', 'generated image')}
            </div>
          </div>
          <br/>

          <div className='illustAboutImageContainer'>
            <div>
              <img className='illustAboutUpscaleImage'
                src={IllustInputImage6}
              />
              {t('aboutBgRemove03', 'input image')}
            </div>
            <div>
              <img className='illustAboutUpscaleImage'
                src={IllustImage6}
              />
              {t('aboutIllustGenerator06', 'generated image')}
            </div>
          </div>
            <br/>

            <div>
              <span>
                <Link to="/illustration-generator">
                  Go to Illustration Generator Page (more examples)
                </Link>
              </span>
            </div>
            <br/>

          </div>
          <br/>

          <div>
            <strong>
              Illustration Upscaler
            </strong>
          </div>

          <p>
          {t('aboutIllustGenerator04', 'Because it upscales image size, it can be used for restoring low-quality illustration image.')}
          </p>
          <br/>

          <p>{t('aboutIllustGenerator05', 'Example of Illustration Upscale')}</p>
          <br/>

          <div className='illustAboutImageContainer'>
            <div>
              <img className='illustAboutUpscaleImage'
                src={upscaleInputImage}
              />
              {t('aboutBgRemove03', 'input image')}
            </div>
            <div>
              <img className='illustAboutUpscaleImage'
                src={UpscaleImage}
              />
              {t('aboutIllustGenerator06', 'upscaled image')}
            </div>
          </div>
          <br/>

          <div>
            <span>
              <Link to="/illustration-generator">
              Go to Illustration Generator Page
              </Link>
            </span>
          </div>
    </div>
  )


  
  return (
    <Fragment>
      {illustAboutBody}
    </Fragment>
  );
}

export default AboutIllustGenerator;
