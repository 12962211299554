import { useState, useEffect, Fragment } from 'react';
// import _, { isLength } from 'lodash';
// import axios from 'axios';
import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';

import DrawResultAction from './DrawResultAction';
// import BucketObjectList from './BucketObjectList';
import { useHookStore } from '../../hook-store/store';

import { useStore } from '../../lib/states';
import { srcToFile } from '../../lib/utils';
// import * as bucketObjectUtils from '../../utils/bucket-object-util';
// import * as fileMoveUtils from '../../utils/file-move-util';

// import { BACKEND_URL } from '../../App';
// import { marks } from '../../../utils/marks';
import classes from './UploadImage.module.css';

function UploadImage(props) {

  const { 

  } = props;

  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  const editorFile = useStore((state) => state.file);
  const renders = useStore((state) => state.editorState.renders);

  const [currentRenderFile, setCurrentRenderFile] = useState();

  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  useEffect(() => {
    createCurrentRenderFile(editorFile);
  },[]);

  const createCurrentRenderFile = async (editorFile) => {
    let targetFile;
    const ext = editorFile.type.split('/')[1];

    if (renders.length > 0) {
      const lastRender = renders[renders.length - 1]
      targetFile = await srcToFile(
        lastRender.currentSrc, 
        `inpaint-${Date.now()}.${ext}`, 
        editorFile.type
      );
    }

    // console.log(targetFile);

    if (targetFile) {
      setCurrentRenderFile(targetFile);
    }

    return targetFile
  }

  let uploadImageBody;

  uploadImageBody = (
    <div>
      <Backdrop 
        zIndex={'95'}
        onCancel={() => { 
          hDispatch('SET_SHOWIMAGEUPLOAD', false)
        }}
      />
      <Modal
        onClose={() => {
          hDispatch('SET_SHOWIMAGEUPLOAD', false);
        }}
      >
        <div className={classes.uploadImageSection}>
          <div className={classes.uploadImageTitle}>
            Upload Generated Image to Kura Image Photo
          </div>
        </div>
        <DrawResultAction 
          currentRenderFile={currentRenderFile}
        />
      </Modal>
    </div>
  )


  
  return (
    <Fragment>
      {uploadImageBody}
    </Fragment>
  );
}

export default UploadImage;
