import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import Notification from "../Notification/Notification";
import { useHookStore } from "../../hook-store/store";
// import "./App.css";
// import "./style.css";
import "./BackgroundRemoveApp.css";

import BgRemoveHeader from "./BgRemoveHeader/BgRemoveHeader";
import BackgroundRemove from "./BackgroundRemove";

function BackgroundRemoveApp(props) {

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useHookStore();
  const { 
    notification, 
    showToggleNav,
   } = hStore.uiStore;

  const [showEl, setShowEl] = useState(true);
  const [iopaintFileLoaded, setIopaintFileLoaded] = useState(false);

  useEffect(() => {
    if (!showEl) {
      setShowEl(true);
    }
  },[showEl]);
  return (
    <Fragment>
      <div className="bgRemove">

        <BgRemoveHeader />

        {showEl && (
          <BackgroundRemove 
            iopaintFileLoaded={iopaintFileLoaded}
            setIopaintFileLoaded={setIopaintFileLoaded}
          />
        )}
        <div className="bgRemoveReset"
          // style={{height:"1rem", border: "1px solid gray"}}
        >
          <button className="iopaintBtnBase"
            onClick={() => { 
              setShowEl(false);
            }}
          >
            {t('bgRemover01', ' Reset image')}
          </button>
        </div>

        {notification && (
          <div>
            <Notification 
              status={notification.status}
              title={notification.title}
              message={notification.message}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default BackgroundRemoveApp;
