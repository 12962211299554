import React, { Fragment, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Backdrop from './components/Backdrop/Backdrop.js';
import Loader from './components/Loader/Loader.js';
import Iopaint from "./Iopaint.tsx"
import Notification from "./components/Notification/Notification"

import { useHookStore } from './hook-store/store';
import Layout from './components/Layout/Layout.js';

import GetWindowData from './components/UIs/GetWindowData.js';
import GetDeferredPrompt from './components/UIs/GetDeferredPrompt.js';
import HeadMetaTag from './components/UIs/HeadMetaTag.js';
import GetAuth from './components/Auth/GetAuth.js';
import AuthCheck from './components/Auth/AuthCheck.js';
import GetLoginUsers from './components/Auth/GetLoginUsers.js';
import SetUserNameData from './components/Auth/SetUserNameData.js';
import FileUploadSocket from './components/SocketConnect/FileUploadSocket.js';
import ServiceBuckets from './components/BucketComponents/ServiceBuckets/ServiceBuckets';
import Bucket from './components/BucketComponents/Bucket/Bucket.js';

import BackgroundRemoveApp from './components/BackgroundRemove/BackgroundRemoveApp.jsx';
import IllustrationGenerator from './components/IllustrationGenerator/IllustrationGenerator.jsx';
import PrivacyPolicy from './pages/PlivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
// import UserSocketConnect from './components/SocketConnect/UserSocketConnect.js';

// import './App.css';

function App() {

  const [store, dispatch] = useHookStore();
  const { 
    isAuth, 
    gLoading, 
    srvLoading,
    metaLoading,
    isAdmin,
    isAllApis,
    // isBackendApi,
    notification,
  } = store.uiStore;


  let routesBody;

  if (isAuth) {
    routesBody = (
      <div>
        <Routes>

          <Route path="privacypolicy" 
            element={<PrivacyPolicy />} 
          />
          <Route path="termsofuse" 
            element={<TermsOfUse />} 
          />
          {/* <Route path="about" 
            element={<AboutPage />} 
          /> */}

          <Route path="background-remover"
            element={<BackgroundRemoveApp />} 
          />

          <Route path="illustration-generator"
            element={<IllustrationGenerator />} 
          />
          
          <Route path="*" 
            element={<Iopaint />} 
          />
        </Routes>

      </div>

    )
  }
  else {
    routesBody = (
    <div>
      <Routes>

        <Route path="privacypolicy" 
          element={<PrivacyPolicy />} 
        />
        <Route path="termsofuse" 
          element={<TermsOfUse />} 
        />
        {/* <Route path="about" 
          element={<AboutPage />} 
        /> */}

        <Route path="background-remover"
          element={<BackgroundRemoveApp />} 
        />

        <Route path="illustration-generator"
          element={<IllustrationGenerator />} 
        />

        <Route path="*" 
          // element={<AuthRequired />} 
          element={<Iopaint />} 
        />
      </Routes>
    </div>
    )
  }

  return (
    <Fragment>
      {/* {(gLoading || srvLoading || metaLoading) && (
        <div>
          <Backdrop 
            zIndex={'500'}
            onCancel={() => { 
              if (!gLoading) {
                // setClickedObjectKeyHandler(''); 
              }
            }}
          />
          <span className='gLoader'>
            <Loader />
          </span>
        </div>
      )} */}
      <BrowserRouter>
        {/* <Layout>
          {routesBody}
        </Layout> */}

        {routesBody}

        <GetWindowData />
        <GetDeferredPrompt />
        <HeadMetaTag />
  
        <GetAuth />
        <AuthCheck />
        <div style={{display:'none'}}>
          <GetLoginUsers />
        </div>

        {isAuth && (
          <div>
            {/* <Register /> */}
            {/* <GetAllObjects /> */}
            {/* <GetObjectMetadata /> */}
            {/* <GetSubscriptionData /> */}
            <ServiceBuckets />
            <Bucket />
            {/* <ToolsDataSocket /> */}

            <FileUploadSocket />
            <SetUserNameData />
            {/* <UserSocketConnect /> */}
          </div>
        )}

        {notification && (
          <div>
            <Notification 
              status={notification.status}
              title={notification.title}
              message={notification.message}
            />
          </div>
        )}

      </BrowserRouter>
    </Fragment>
  );
}

export default App;
