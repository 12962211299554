// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from "react"
import ReactDOM from "react-dom/client"
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import "inter-ui/inter.css"
import Iopaint from "./Iopaint.tsx"
import App from "./App.jsx"
import "./globals.css"
import './index.css';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { SUBSCRIPTION_BACKEND_URL } from "./lib/api.ts"

import { ThemeProvider } from "next-themes"
import { TooltipProvider } from "./components/ui/tooltip.tsx"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import './i18n';

import configureIopaintStore from './hook-store/iopaint-store'
import configureIllustrationGeneratorStore from './hook-store/illustration-generator-store.js';
import configureUiStore from './hook-store/ui-store';
import configureBucketObjectStore from './hook-store/bucket-object-store';
import configureStripeDataStore from "./hook-store/stripe-data-store";
import configureServiceBucketsStore from './hook-store/service-buckets-store';
import configureObjectMetadataStore from './hook-store/object-metadata-store';

configureUiStore();
configureIopaintStore();
configureIllustrationGeneratorStore();
configureUiStore();
configureBucketObjectStore();
configureStripeDataStore();
configureServiceBucketsStore();
configureObjectMetadataStore();


const queryClient = new QueryClient()

// ReactDOM.createRoot(document.getElementById("root")!).render(
//   <React.StrictMode>
//     <QueryClientProvider client={queryClient}>
//       <ThemeProvider defaultTheme="dark" disableTransitionOnChange>
//         <TooltipProvider>
//           <App />
//         </TooltipProvider>
//       </ThemeProvider>
//     </QueryClientProvider>
//   </React.StrictMode>
// )

// ReactDOM.createRoot(document.getElementById("root")!).render(
//   <React.StrictMode>
//     {/* <Iopaint /> */}
//     <App />
//   </React.StrictMode>
// )



const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="dark" disableTransitionOnChange>
        <TooltipProvider>
          <App />
        </TooltipProvider>
      </ThemeProvider>
    </QueryClientProvider>
      
  // </React.StrictMode>
);


//// load stripe publishableKey
fetch(SUBSCRIPTION_BACKEND_URL + '/price/config')
  .then(r => {
    return r.json()
  })
  .then(resData => {
    console.log('stripe-config resData', resData);
    const stripePromise = loadStripe(resData.publishableKey);

    // const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      // <React.StrictMode>
      <Elements stripe={stripePromise}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider defaultTheme="dark" disableTransitionOnChange>
            <TooltipProvider>
              <App />
            </TooltipProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </Elements>
      // </React.StrictMode>
    );

  })
  .catch(err => {
    // console.log('stripe-config err', err)
  });