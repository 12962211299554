import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHookStore } from "../../hook-store/store";
import { getAuthInfo, logoutHandler } from "../../utils/user/user";


const GetAuth = (props) => {
  const { } = props;

  const  navigate = useNavigate();

  // const [authCheckStart, setAuthCheckStart] = useState(false);

  const [store, dispatch] = useHookStore();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const expiryDate = localStorage.getItem("expiryDate");
  
    const tokenRemainNum = new Date(expiryDate).getTime() - new Date().getTime();
    console.log('tokenRemainNum', tokenRemainNum);

    if (!token || tokenRemainNum < 0) {
      getAuthInfo()
      .then((result) => {
        console.log('get authinfo result', result);
    
        const token = localStorage.getItem("token");
        const expiryDate = localStorage.getItem("expiryDate");
        const userId = localStorage.getItem('userId');
        const email = localStorage.getItem('email');
        if (!token || !expiryDate || !userId || !email) {
          dispatch('SET_ISAUTH', false);
          logoutHandler();
          return;
        }
    
        // console.log('date', new Date().getTime() - new Date(expiryDate).getTime())
        const tokenRemainNum = new Date(expiryDate).getTime() - new Date().getTime();
        console.log('tokenRemainNum', tokenRemainNum);
        
        if (tokenRemainNum <= 0) {
          // dispatch("SET_AUTHSTATE", false);
          dispatch('SET_ISAUTH', false);
          logoutHandler();
          return;
        }
        
        // dispatch("SET_AUTHSTATE", true);
        // window.location.replace('/');
    
        // const userPath = `/?customer-email=${userLoginEmail}`
        // history.replace(userPath);
        setIsLoading(false);
        // window.location.href = window.location.origin;
        navigate("/");
        dispatch('SET_ISAUTH', true);
        // window.location.replace('/');
        // history.replace('/');
        // window.location.reload();
    
      })
      .catch((err) => {
        console.log('get authinfo error', err);
    
        err.message = "Login failed ...";
        
        setIsLoading(false);
        // dispatch("SET_AUTHSTATE", false);
        dispatch('SET_ISAUTH', false);
        logoutHandler();
      });
    }
    else {
      dispatch('SET_ISAUTH', true);
    }
  },[]);


  


  return <Fragment></Fragment>;
};

export default GetAuth;
