import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_SERVERPROCESSING: (state, payload) => {
      return { 
        iopaintStore : {
          ...state.iopaintStore,
          serverProcessing: payload, 
        }
      };
    },
    SET_ISUSERRUNNING: (state, payload) => {
      return { 
        iopaintStore : {
          ...state.iopaintStore,
          isUserRunning: payload, 
        }
      };
    },
    SET_SHOWIMAGEUPLOAD: (state, payload) => {
      return { 
        iopaintStore : {
          ...state.iopaintStore,
          showUploadImage: payload, 
        }
      };
    },
    SET_SHOWABOUTIOPAINT: (state, payload) => {
      return { 
        iopaintStore : {
          ...state.iopaintStore,
          showAboutIopaint: payload, 
        }
      };
    },
    SET_SHOWINPAINTSETTINGS: (state, payload) => {
      return { 
        iopaintStore : {
          ...state.iopaintStore,
          showInpaintSettings: payload, 
        }
      };
    },
    SET_SHOWINPAINTFILES: (state, payload) => {
      return { 
        iopaintStore : {
          ...state.iopaintStore,
          showInpaintFiles: payload, 
        }
      };
    },
    SET_CURRENTINPAINTFILE: (state, payload) => {
      return { 
        iopaintStore : {
          ...state.iopaintStore,
          currentInpaintFile: payload, 
        }
      };
    },
    // SET_INVALIDFILE: (state, payload) => {
    //   return { 
    //     iopaintStore : {
    //       ...state.iopaintStore,
    //       invalidFile: payload, 
    //     }
    //   };
    // },
    // SET_RESETSETTINGS: (state, payload) => {
    //   return { 
    //     iopaintStore : {
    //       ...state.iopaintStore,
    //       resetSettings: payload, 
    //     }
    //   };
    // },
  };

  initStore(actions, {
    iopaintStore: {
      serverProcessing: false,
      isUserRunning: false,
      showUploadImage: false,
      showAboutIopaint: false,
      showInpaintSettings: false,
      showInpaintFiles: false,
      currentInpaintFile: null,
      // invalidFile: false,
      // resetSettings: false,
    }
  });
};

export default configureStore;
