import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useHookStore } from '../../hook-store/store';
import { kuraImagePhotoUrl } from '../../lib/api';
// import { marks } from '~utils/marks'
import { marks } from '../../utils/marks';

import classes from './UploadImage.module.css';

const ImagePhotoAuthInfo = (props) => {
  const { guideImageFile } = props

  const [t] = useTranslation('translation')

  const [store, dispatch] = useHookStore()
  // const {
  //   bucketName,
  //   topLevels,
  //   totalSizeGB,
  //   limitGB,
  //   // selectedPrefix,
  // } = store.bucketObjectStore
  // const { gLoading, isAuth } = store.uiStore
  // const { serviceBucketsDataList } = store.serviceBucketsStore

  let imagePhotoAuthInfoBody = (
    <div>
      <div className={classes.uploadImageSection}>
        <p>
          Kura Image Photo{' '}
          {/* {t('aboutPage.01', 'provides simple files and images storage space with image editor.')} */}
          {t('uploadInfo01', 'provides simple files and images storage space.')}

        </p>{' '}
        <p>
        {t('uploadInfo09', 'It has various image editing and generation tools as well.')}
        </p>
        <p>
          {t(
            'uploadInfo02',
            'There is free space to upload files. Created image files can be stored in the free file storage space, after login.'
          )}
          <br />
          {t(
            'uploadInfo03',
            'It is possible to access uploaded files from both mobile and desktop devices.'
          )}
        </p>
      </div>

      <div className={classes.uploadImageSection}>
        <Link to={`${kuraImagePhotoUrl}/about`} target="_blank" rel="noreferrer noopener">
          {t('uploadInfo10', 'More detail')} 
          {' '}
          about Kura Image Photo {marks.externalLinkMark}
        </Link>
      </div>


      {/* <div className={classes.uploadImageSection}>
        <Link to={kuraImagePhotoUrl} target="_blank" rel="noreferrer noopener">
          {t('uploadInfo04', 'Go to')} Kura Image Photo for login or signup {marks.externalLinkMark}
        </Link>
      </div> */}

      <div>
        {t('uploadInfo12', 'It is possible to login or create account (signup) from menu of this page.')}
        <br/>
        ({t('uploadInfo13', 'Please make sure to download or save generated file before login or signup')}) 
      </div>
      <br/>
      
      <div>
        {t('uploadInfo14', 'After login in this page, it is possible to upload generated image file from this page ')}
        {' '}
        to Kura Image Photo.
      </div>
      <br/>
      
      {/* <div>
        <p>
          {t('uploadInfo06', 'To login in this page, User need to login at Kura Image Photo page.')}
          {' '} 
          {t('uploadInfo07', 'Then, user need to  come to this page from link in menu list of Kura Image Photo page.')}
          <br/>
          {t('uploadInfo08', 'After successful login in this page, your email address will be shown on top of the menu list in this page. Menu list can be opened by pressing menu button in this page.')}
        </p>
      </div> */}
    </div>
  )

  return <Fragment>{imagePhotoAuthInfoBody}</Fragment>
}

export default ImagePhotoAuthInfo
