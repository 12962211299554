import { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
// import ImagePhotoAuthInfo from '../UploadImage/ImagePhotoAuthInfo';
import { useHookStore } from '../../hook-store/store';

// import { kuraImagePhotoUrl } from '../../lib/api';

// import InputImage from '../../image/for-iopaint/iopaint-input-200.png';
// import MaskImage from '../../image/for-iopaint/iopaint-mask-200.png';
// import InpaintImage from '../../image/for-iopaint/iopaint_cleanup-a-woman-face-200.png';
// import CnImage from '../../image/for-iopaint/iopaint-controlnet-canny-200.png';

// import classes from './AboutIopaint.module.css';

import DiffusionOptions from '../SidePanel/DiffusionOptions';


function InpaintSettings(props) {

  const { 

  } = props;

  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);



  

  

  let inpaintSettingsBody;

  inpaintSettingsBody = (
    <div>
      <Backdrop 
        zIndex={'95'}
        onCancel={() => { 
          hDispatch('SET_SHOWINPAINTSETTINGS', false)
        }}
      />
      <Modal
        onClose={() => {
          hDispatch('SET_SHOWINPAINTSETTINGS', false);
        }}
      >
        <DiffusionOptions />
      </Modal>
    </div>
  )


  
  return (
    <Fragment>
      {inpaintSettingsBody}
    </Fragment>
  );
}

export default InpaintSettings;
