import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { AutoModel, AutoProcessor, env, RawImage } from "@xenova/transformers";
import download from 'downloadjs';

import { useStore } from "../../lib/states"; 

import Loader from "../Loader/Loader";
import BgRemoveUpload from "./BgRemoveUpload/BgRemoveUpload";
import LoadParamFile from "../FileControl/LoadParmFile";
import { useHookStore } from "../../hook-store/store";

import { marks } from "../../utils/marks";
import "./App.css";
// import "./style.css";
import "./BackgroundRemove.css";


function BackgroundRemove(props) {
  const { 
    iopaintFileLoaded,
    setIopaintFileLoaded,
  } = props;

  const [t] = useTranslation('translation');

  let [searchParams, setSearchParams] = useSearchParams();

  const [file] = useStore((state) => [state.file]);

  const [hStore, hDispatch] = useHookStore();
  const { currentInpaintFile } = hStore.iopaintStore;
  // console.log('file store', file);

  const [selectedFileUrl, setSelectedFileUrl] = useState('');
  const [canvasImg64Data, setCanvasImg64Data] = useState('');
  const [removeState, setRemoveState] = useState('');
  const [isRemoving, setIsRemoving] = useState(false);
  const [showOriginal, setShowOriginal] = useState(false);
  const [imageDimension, setImageDimension] = useState({
    width: 0, 
    height: 0,
  });
  const [showUploadModal, setShowUploadModal] = useState(false);
  
  // Since we will download the model from the Hugging Face Hub, we can skip the local model check
  env.allowLocalModels = false;

  // Proxy the WASM backend to prevent the UI from freezing
  env.backends.onnx.wasm.proxy = true;

  // Constants
  const EXAMPLE_URL =
    "https://images.pexels.com/photos/5965592/pexels-photo-5965592.jpeg?auto=compress&cs=tinysrgb&w=1024";

  useEffect(() => {
    // const example = document.getElementById("example");
    // example.addEventListener("click", (e) => {
    //   e.preventDefault();
    //   predict(EXAMPLE_URL);
    // });

    const fileUpload = document.getElementById("upload");

    if (fileUpload) {
      fileUpload.addEventListener("change", function (e) {
        const file = e.target.files[0];
        if (!file) {
          return;
        }
  
        const reader = new FileReader();
  
        // Set up a callback when the file is loaded
        // reader.onload = (e2) => predict(e2.target.result);
        reader.onload = (e2) => {
          setSelectedFileUrl(e2.target.result);
          setSelectedDisplayImage(e2.target.result);
        };
  
        reader.readAsDataURL(file);
      });
    }
  }, []);

  useEffect(() => {
    if (!iopaintFileLoaded) {
      loadIopaintFileHandler();
    }
  },[]);

  async function setSelectedDisplayImage(url) {

    // Read image
    const image = await RawImage.fromURL(url);

    // Update UI
    const imageContainer = document.getElementById("container");


    imageContainer.innerHTML = "";
    imageContainer.style.backgroundImage = `url(${url})`;

    // Set container width and height depending on the image aspect ratio
    const ar = image.width / image.height;
    const [cw, ch] = ar > 720 / 480 ? [720, 720 / ar] : [480 * ar, 480];

    // imageContainer.style.width = `${cw}px`;
    // imageContainer.style.height = `${ch}px`;

    // console.log(cw, ch, window.innerWidth);
    let newCw = cw;
    let newCh = ch;
    if (cw > window.innerWidth) {
      newCw = window.innerWidth * 0.95;
      newCh = ( window.innerWidth / cw ) * ch * 0.95
    }

    // console.log(cw, ch, newCw, newCh);

    imageContainer.style.width = `${newCw}px`;
    imageContainer.style.height = `${newCh}px`;

    setImageDimension({
      width: newCw,
      height: newCh,
    });
  }

  
  async function removeBackground(url) {
    try {
      setIsRemoving(true);

      // const status = document.getElementById("status");
  
      // status.textContent = 'Loading model...';
  
      // Read image
      const image = await RawImage.fromURL(url);
      
      console.log(image);
      // Update UI
      const imageContainer = document.getElementById("container");
  
      // imageContainer.innerHTML = "";
      // imageContainer.style.backgroundImage = `url(${url})`;
  
      // // Set container width and height depending on the image aspect ratio
      // const ar = image.width / image.height;
      // const [cw, ch] = ar > 720 / 480 ? [720, 720 / ar] : [480 * ar, 480];
      // imageContainer.style.width = `${cw}px`;
      // imageContainer.style.height = `${ch}px`;
  
      // status.textContent = 'Analysing...';
  
      // throw new Error('error-error');

      //// Preprocess image
      // const processor = await AutoProcessor.from_pretrained("briaai/RMBG-1.4", {
      const processor = await AutoProcessor.from_pretrained("AllenGG/RMBG-1.4-bs1", {
        // Do not require config.json to be present in the repository
        config: {
          do_normalize: true,
          do_pad: false,
          do_rescale: true,
          do_resize: true,
          image_mean: [0.5, 0.5, 0.5],
          feature_extractor_type: "ImageFeatureExtractor",
          image_std: [1, 1, 1],
          resample: 2,
          rescale_factor: 0.00392156862745098,
          size: { width: 1024, height: 1024 },
        },
      });
  
      // status.textContent = "Ready";
  
      const { pixel_values } = await processor(image);
  
      // const processData = await processor(image);
      // console.log(processData);
  
      // Predict alpha matte
  
      // status.textContent = "Loading model...";
      setRemoveState('Loading model...');
  
      const model = await AutoModel.from_pretrained("briaai/RMBG-1.4", {
        // Do not require config.json to be present in the repository
        config: { model_type: "custom" },
      });
  
      // status.textContent = "Analysing...";
      setRemoveState('Analysing...');
  
      const { output } = await model({ input: pixel_values });
  
      // console.log(output)
  
      // Resize mask back to original size
      const mask = await RawImage.fromTensor(
        output[0].mul(255).to("uint8")
      ).resize(image.width, image.height);
      
      console.log(mask);
  
      // Create new canvas
      const canvas = document.createElement("canvas");
  
      canvas.id = "image-canvas";
  
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext("2d");
  
      // Draw original image output to canvas
      ctx.drawImage(image.toCanvas(), 0, 0);
  
      // Update alpha channel
      const pixelData = ctx.getImageData(0, 0, image.width, image.height);
      for (let i = 0; i < mask.data.length; ++i) {
        pixelData.data[4 * i + 3] = mask.data[i];
      }
      ctx.putImageData(pixelData, 0, 0);
  
      // Update UI
      imageContainer.append(canvas);
      imageContainer.style.removeProperty("background-image");
      imageContainer.style.background = `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURb+/v////5nD/3QAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAUSURBVBjTYwABQSCglEENMxgYGAAynwRB8BEAgQAAAABJRU5ErkJggg==")`;
      // status.textContent = "Done!";
      setRemoveState('Done');
  
      // console.log(imageContainer);
      // console.log(canvas.toDataURL("image/png"))
      setCanvasImg64Data(canvas.toDataURL("image/png"));

      setIsRemoving(false);

    } catch(err) {
      console.log(err);
      setIsRemoving(false);
      setRemoveState('Remove Failed');
    }
   
  }

  const downloadHandler = () => {
    download(
      canvasImg64Data, 
      `bgremove-${Date.now()}.png`, 
      'image/png'
    );
  };


  const loadIopaintFileHandler = () => {
    const inpaintParam = searchParams.get('inpaintFile');
    
    if (iopaintFileLoaded) {
      return;
    }
    // console.log(inpaintParam, currentInpaintFile)
    if (inpaintParam === 'inpaint' && currentInpaintFile) {
      const fileUrl = URL.createObjectURL(currentInpaintFile);
      setSelectedFileUrl(fileUrl);
      setSelectedDisplayImage(fileUrl);

      setIopaintFileLoaded(true);
    }

    if (inpaintParam === 'input' && currentInpaintFile) {
      const fileUrl = URL.createObjectURL(file);
      setSelectedFileUrl(fileUrl);
      setSelectedDisplayImage(fileUrl);

      setIopaintFileLoaded(true);
    }
  };


  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-dream-inpainter.spaceeight.net-1713343913172"
        style={{width:"302px", height:"67px", border:"none"}}    
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-dream-inpainter.spaceeight.net-1713343913172"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-dream-inpainter.spaceeight.net-1713343935915"
        style={{width:"452px", height:"92px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-dream-inpainter.spaceeight.net-1713343935915"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-dream-inpainter.spaceeight.net-1713343954996"
        style={{width:"602px", height:"132px", border:"none"}} 
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-dream-inpainter.spaceeight.net-1713343954996"
      />
    );
  }

  return (
    <>
    <div>

      <div 
        style={{display: 'flex', justifyContent: "center", paddingBottom: "2rem" }}
      >
        {bannerElement}
      </div>

      <div className="bgRemoveSection">
        <div className="bgRemoveTitle">
          Background Remover
        </div>
      </div>

      <div className="bgRemoveSection">
        <div id="container"
          className="imageContainer"
        >
          <label id="upload-button" for="upload">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#000"
                d="M3.5 24.3a3 3 0 0 1-1.9-.8c-.5-.5-.8-1.2-.8-1.9V2.9c0-.7.3-1.3.8-1.9.6-.5 1.2-.7 2-.7h18.6c.7 0 1.3.2 1.9.7.5.6.7 1.2.7 2v18.6c0 .7-.2 1.4-.7 1.9a3 3 0 0 1-2 .8H3.6Zm0-2.7h18.7V2.9H3.5v18.7Zm2.7-2.7h13.3c.3 0 .5 0 .6-.3v-.7l-3.7-5a.6.6 0 0 0-.6-.2c-.2 0-.4 0-.5.3l-3.5 4.6-2.4-3.3a.6.6 0 0 0-.6-.3c-.2 0-.4.1-.5.3l-2.7 3.6c-.1.2-.2.4 0 .7.1.2.3.3.6.3Z"
              ></path>
            </svg>
            {marks.pictureOMark} {t('bgRemover07', 'Select image')}
            {/* <label id="example">(or try example)</label> */}
          </label>
          <input id="upload" type="file" accept="image/*" />
        </div>
      </div>

      {/* <label id="status"></label> */}
      {removeState && (
        <div className="bgRemoveSection">
          {removeState}
        </div>
      )}
      {isRemoving && (
        <Loader />
      )}

      {selectedFileUrl && (
        <div className="bgRemoveSection">
          <button className="iopaintBtnBase"
            disabled={isRemoving}
            onClick={() => { 
              setShowOriginal(!showOriginal); 
            }}
          >
            {showOriginal && (
              <span>{t('bgRemover02', 'Hide input image')}</span>
            )}
            {!showOriginal && (
              <span>{t('bgRemover03', 'Display input image')}</span>
            )}
          </button>
        </div>
      )}

      {showOriginal && (
        <div className="bgRemoveSection">
          <div className="oriImageContainer">
            <img 
              style={{ 
                width: `${imageDimension.width}px`, 
                height: `${imageDimension.height}px`, 
                objectFit: "fill"
              }}
              src={selectedFileUrl}
            />
          </div>
        </div>
      )}

      {!canvasImg64Data && (
        <div className="bgRemoveSection"
          style={selectedFileUrl ? { display: "block"} : {display: "none"}}>
          <button className="iopaintBtnBase"
            style={{fontWeight:"bold", padding: "1rem"}}
            disabled={isRemoving}
            onClick={() => { 
              removeBackground(selectedFileUrl); 
            }}
          >
            {t('bgRemover04', 'Remove background')} {marks.eraserMark}
          </button>
        </div>
      )}
      
      {canvasImg64Data && (
        <div className="bgRemoveSection">
          <div className="bgRemoveActionButtons">
          <button className="iopaintBtnBase"
            disabled={isRemoving}
            onClick={downloadHandler}
          >
            {t('bgRemover05', 'Download')} {marks.downloadMark}
          </button>
          <button className="iopaintBtnBase"
            disabled={isRemoving}
            onClick={() => {
              setShowUploadModal(true);
            }}
          >
            {t('bgRemover06', 'Upload')} {marks.uploadMark}
          </button>
          </div>
        </div>
      )}

      {showUploadModal && (
        <BgRemoveUpload 
          canvasImg64Data={canvasImg64Data}
          setShowUploadModal={setShowUploadModal}
        />
      )}

      <LoadParamFile 
        setSelectedFileUrl={setSelectedFileUrl}
        setSelectedDisplayImage={setSelectedDisplayImage}
      />
    </div>
    </>
  );
}

export default BackgroundRemove;




// // Predict foreground of the given image
  // async function predict(url) {
  //   const status = document.getElementById("status");

  //   // status.textContent = 'Loading model...';

  //   // Read image
  //   const image = await RawImage.fromURL(url);

  //   // Update UI
  //   const imageContainer = document.getElementById("container");
  //   imageContainer.innerHTML = "";
  //   imageContainer.style.backgroundImage = `url(${url})`;

  //   // Set container width and height depending on the image aspect ratio
  //   const ar = image.width / image.height;
  //   const [cw, ch] = ar > 720 / 480 ? [720, 720 / ar] : [480 * ar, 480];
  //   imageContainer.style.width = `${cw}px`;
  //   imageContainer.style.height = `${ch}px`;

  //   // status.textContent = 'Analysing...';

  //   // Preprocess image

  //   const processor = await AutoProcessor.from_pretrained("briaai/RMBG-1.4", {
  //     // Do not require config.json to be present in the repository
  //     config: {
  //       do_normalize: true,
  //       do_pad: false,
  //       do_rescale: true,
  //       do_resize: true,
  //       image_mean: [0.5, 0.5, 0.5],
  //       feature_extractor_type: "ImageFeatureExtractor",
  //       image_std: [1, 1, 1],
  //       resample: 2,
  //       rescale_factor: 0.00392156862745098,
  //       size: { width: 1024, height: 1024 },
  //     },
  //   });

  //   status.textContent = "Ready";

  //   const { pixel_values } = await processor(image);

  //   // const processData = await processor(image);
  //   // console.log(processData);

  //   // Predict alpha matte

  //   status.textContent = "Loading model...";

  //   const model = await AutoModel.from_pretrained("briaai/RMBG-1.4", {
  //     // Do not require config.json to be present in the repository
  //     config: { model_type: "custom" },
  //   });

  //   status.textContent = "Analysing...";

  //   const { output } = await model({ input: pixel_values });

  //   // console.log(output)

  //   // Resize mask back to original size
  //   const mask = await RawImage.fromTensor(
  //     output[0].mul(255).to("uint8")
  //   ).resize(image.width, image.height);
    
  //   console.log(mask);

  //   // Create new canvas
  //   const canvas = document.createElement("canvas");
    
  //   canvas.id = "image-canvas";

  //   canvas.width = image.width;
  //   canvas.height = image.height;
  //   const ctx = canvas.getContext("2d");

  //   // Draw original image output to canvas
  //   ctx.drawImage(image.toCanvas(), 0, 0);

  //   // Update alpha channel
  //   const pixelData = ctx.getImageData(0, 0, image.width, image.height);
  //   for (let i = 0; i < mask.data.length; ++i) {
  //     pixelData.data[4 * i + 3] = mask.data[i];
  //   }
  //   ctx.putImageData(pixelData, 0, 0);

  //   // Update UI
  //   imageContainer.append(canvas);
  //   imageContainer.style.removeProperty("background-image");
  //   imageContainer.style.background = `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURb+/v////5nD/3QAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAUSURBVBjTYwABQSCglEENMxgYGAAynwRB8BEAgQAAAABJRU5ErkJggg==")`;
  //   status.textContent = "Done!";

  //   // console.log(imageContainer);
  //   // console.log(canvas.toDataURL("image/png"))
  //   setCanvasImg64Data(canvas.toDataURL("image/png"));
  // }