// import { BACKEND_URL, SUBSCRIPTION_BACKEND_URL } from '../App';
import { SUBSCRIPTION_BACKEND_URL } from '../lib/api';

export const fetchPrices = async () => {
  return new Promise( async (resolve, reject) => {
    // const {prices} = await fetch(BACKEND_URL + '/config').then(r => r.json());
    const { prices, publishableKey, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/price/plan-prices'
    , {
      // method: 'GET',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          // Authorization: 'Bearer ' + localStorage.getItem('token').replace(/\r?\n|\r/g, ''),
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      }
    ).then(r => r.json());
    
    if (error) { 
      // console.log(error);
      reject(error); 
    }

    // resolve(prices);
    resolve({
      prices: prices,
      publishableKey: publishableKey
    });
  });
};

export const stripeLogout = () => {
  localStorage.removeItem('customerId');
  localStorage.removeItem('customerEmail');
}

export const getStripeCustomer = (emailAddr, token) => {
  return new Promise(async (resolve, reject) => {
    const { customer, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/customer/retrieve-email-customer', {
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: emailAddr,
      }),
    })
    .then(r => {
      return r.json()
    })
    // .then(resData => {
    //   console.log(resData);
    // })
    // .catch(err => {
    //   console.log(err);
    // });

    // console.log(customer, error);

    if (customer) {
      localStorage.setItem('customerId', customer.id);
      localStorage.setItem('customerEmail', customer.email);

      resolve(customer);
    }
    if (error) {
      // console.log(error);

      reject(error);
    }
  })
};

export const getCustomerSubscriptions = (customerId, token, email) => {
  return new Promise(async (resolve, reject) => {
    // const {subscriptions} = await fetch(BACKEND_URL + '/subscriptions').then(r => r.json());
      const { subscriptions, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/customer/list-customer-subscriptions', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customerId,
          email: email,
        }),
      }).then(r => r.json());
      
      // console.log(subscriptions);
      resolve(subscriptions);
      
      if (error) {
        // console.log(error);
        reject(error);
      }
      // if (subscriptions) {
      // }
  });
};

export const getCustomerDataSubscriptions = (token, email) => {
  return new Promise(async (resolve, reject) => {
    getStripeCustomer(email, token)
      .then(result => {
        // console.log(result);
        return getCustomerSubscriptions(result.id, token, email);
      })
      .then(subResult => {
        // console.log(subResult);
        resolve(subResult);
      })
      .catch(err => {
        // console.log(err);
        reject(err);
      });
  });
};

export const getSubscriptionState = (subscriptions) => {
  let subscriptionStatus;
  if (subscriptions.length > 0) {
    const subs = [];
    for (const subData of subscriptions) {
      if (
        subData.status === 'active' 
        || subData.status === 'past_due' 
        || subData.status === 'unpaid' 
        || subData.status === 'trialing'
      ) {
        subs.push(subData);
      }
    }
    
    if (subs.length > 0) {
      subscriptionStatus = subs[0].status;
    }

    return subscriptionStatus;
  }
};

export const getCurrentSubscription = (subscriptions) => {
  let currentSub;
  if (subscriptions.length > 0) {
    const subs = [];
    for (const subData of subscriptions) {
      if (
        subData.status === 'active' 
        || subData.status === 'past_due' 
        || subData.status === 'unpaid' 
        || subData.status === 'trialing'
      ) {
        subs.push(subData);
      }
    }
    
    if (subs.length > 0) {
      currentSub = subs[0];
    }

    return currentSub;
  }
};