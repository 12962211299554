// import React from 'react';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useHookStore } from '../../../../hook-store/store';

import { appInstallHandler } from '../../../../utils/ui/ui-util';
import { logoutHandler } from '../../../../utils/user/user';
import { marks } from '../../../../utils/marks';

// import { 
//   loginPageLink, 
//   signupPageLink,
//   drawDreamerLink,
//  } from '../../../../App';
 import { 
  kuraImagePhotoUrl, 
  drawDreamerLink,
  loginPageLink,
  signupPageLink,
} from '../../../../lib/api';

import './ToggleNavItems.css';


const ToggleNavItems = props => {
  // console.log('ToggleNavItems-props', props);

const [t] = useTranslation('translation');

const navigate = useNavigate();
// {t('termsOfUse.text1', 'terms of use')}

  const [store, dispatch] = useHookStore();
  const { 
    isAuth, 
    deferredPrompt, 
    userNameData,
  }  = store.uiStore;

  const navItems = [
    { 
      id: 'dream-inpainter', 
      // text: 'feed',
      text: `Inpainter`, 
      link: `/${window.location.search}`, 
      auth: false
    },
    { 
      id: 'about-content', 
      // text: 'feed',
      text: `About Inpainter`, 
      link: `/${window.location.search}`, 
      auth: false
    },
    { 
      id: 'inpaint-settings', 
      // text: 'feed',
      text: `Inpainter Settings`, 
      link: `/${window.location.search}`, 
      auth: false
    },
    { 
      id: 'inpaint-files', 
      // text: 'feed',
      text: `Inpainter Files`, 
      link: `/${window.location.search}`, 
      auth: false
    },
    { 
      id: 'background-remover', 
      // text: 'feed',
      text: `Background Remover`, 
      link: '/background-remover', 
      auth: false
    },
    { 
      id: 'illustration-generator', 
      // text: 'feed',
      text: `Illustration Generator`, 
      link: '/illustration-generator', 
      auth: false
    },
    { 
      id: 'bucket-object', 
      // text: 'feed',
      text: `${t('nav.01', 'Files')}`, 
      link: '/', 
      auth: true
    },
    { 
      id: 'image-editor', 
      // text: 'feed',
      text: `${t('nav.08', 'Image Editor')}`, 
      link: `${kuraImagePhotoUrl}/image-editor`, 
      auth: false
    },
    { 
      id: 'image-cleaner', 
      // text: 'feed',
      text: 'Image Cleaner', 
      link: `${kuraImagePhotoUrl}/image-cleaner`, 
      auth: false
    },
    { 
      id: 'image-resizer', 
      // text: 'feed',
      text: 'Image Resizer', 
      link: `${kuraImagePhotoUrl}/image-resizer`, 
      auth: false
    },
    { 
      id: 'draw-dreamer', 
      // text: 'feed',
      text: 'Draw Dreamer', 
      link: `${kuraImagePhotoUrl}/image-resizer`, 
      auth: false
    },
    { 
      id: 'image-fusion', 
      // text: 'feed',
      text: 'Image Fusion', 
      link: `${kuraImagePhotoUrl}/image-fusion`, 
      auth: false
    },
    { 
      id: 'image-combiner', 
      // text: 'feed',
      text: 'Image Combiner', 
      link: `${kuraImagePhotoUrl}/image-combiner`, 
      auth: false
    },
    { 
      id: 'image-convert', 
      // text: 'feed',
      text: 'Image Convert', 
      link: `${kuraImagePhotoUrl}/image-convert`, 
      auth: false
    },
    { 
      id: 'kura-image-photo', 
      // text: 'feed',
      text: 'Kura Image Photo', 
      link: `${kuraImagePhotoUrl}`, 
      auth: false
    },
    // { 
    //   id: 'userinfo', 
    //   // text: 'feed',
    //   text: `${t('nav.02', 'User Info')}`, 
    //   link: '/userinfo', 
    //   auth: true
    // },
    { 
      id: 'login', 
      // text: 'feed',
      text: `${t('nav.03', 'Login')}`, 
      link: '/feed/posts', 
      auth: false
    },
    { 
      id: 'signup', 
      // text: 'feed',
      text: `${t('nav.04', 'Signup')}`, 
      link: '/feed/posts', 
      auth: false
    },
    { 
      id: 'install-app', 
      // text: 'feed',
      text: `${t('nav.05', 'Install App')}`, 
      link: '/', 
      auth: false
    },
    { 
      id: 'logout', 
      // text: 'feed',
      text: `${t('nav.06', 'Logout')}`, 
      link: '/feed/posts', 
      auth: true
    },
  ];


  const logoutConfirm = () => {
    if (window.confirm(`${t('nav.07', "Is it no problem to logout?")}`)) {
      console.log('confirm true');
      // props.onLogout();

      dispatch('SET_ISAUTH', false);
      logoutHandler(); 
      navigate("/");
      window.location.reload();
    }
  }

  let toggleNavItemsBody;

  toggleNavItemsBody = (
    <div>
      {localStorage.getItem('email') && (
        <div className='toggleNavItemEmail'>
          {userNameData?.imageUrl && (
            <img className='toggleNavItemUserImage'
              src={userNameData.imageUrl} 
            />
          )}
          <span>
            {localStorage.getItem('email')}
          </span>
        </div>
      )}

      <ul>
        {navItems.map(item => {
          if (item.id === 'dream-inpainter') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  // target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.listUlMark} */}
                    {marks.paintBrushMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'about-content' && window.location.pathname === '/') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  // target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                    dispatch('SET_SHOWABOUTIOPAINT', true);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.questionCircleOMark}  */}
                    {marks.infoCircleMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'inpaint-settings' && window.location.pathname === '/') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  // target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                    dispatch('SET_SHOWINPAINTSETTINGS', true);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.listUlMark} */}
                    {marks.gearMrak}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'inpaint-files' && window.location.pathname === '/') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  // target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                    dispatch('SET_SHOWINPAINTFILES', true);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.fileImageOMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'background-remover') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  // target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.listUlMark} */}
                    {marks.eraserMark} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'illustration-generator') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  // target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.listUlMark} */}
                    {marks.paintBrushMark} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'image-editor') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    // dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.listUlMark} */}
                    {marks.paintBrushMark} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text} {marks.externalLinkMark}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'image-cleaner') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    // dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.listUlMark} */}
                    {marks.eraserMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text} {marks.externalLinkMark}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'image-resizer') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    // dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.compressMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text} {marks.externalLinkMark}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'draw-dreamer') {
            let drawDreamerlink = drawDreamerLink;

            if (localStorage.getItem('tokenForCasTGT') && localStorage.getItem('TGTexp')) {
              drawDreamerlink = `${drawDreamerLink}/?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`
            }

            return (
              <div className="toggleNavItem">
                <a 
                  href={drawDreamerlink} 
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    // dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.pictureOMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    Draw Dreamer {marks.externalLinkMark}
                  </span>
                </a>
              </div>
            );
          }

          if (item.id === 'image-combiner') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    // dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.objectGroupMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text} {marks.externalLinkMark}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'image-fusion') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    // dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.objectGroupMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text} {marks.externalLinkMark}
                  </span>
                </Link>
              </div>
            );
          }

          if (item.id === 'image-convert') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    // dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.exchangeMark}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text} {marks.externalLinkMark}
                  </span>
                </Link>
              </div>
            );
          }
          

          if (item.id === 'kura-image-photo') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  target="_blank"  rel="noreferrer noopener"
                  onClick={() => {
                    // dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text} {marks.externalLinkMark}
                  </span>
                </Link>
              </div>
            );
          }
          if (!isAuth && item.id === 'login') {
            return (
              <div className="toggleNavItem">
                <span>
                  <a href={loginPageLink} >
                    <span className='toggleNavItemIcon'>
                      {marks.signInMrak}
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text}
                    </span>
                  </a>
                </span>
              </div>
            );
          }

          if (!isAuth && item.id === 'signup') {
            return (
              <div className="toggleNavItem">
                <span>
                  <a href={signupPageLink} >
                  <span className='toggleNavItemIcon'>
                    {marks.userPlusMrak}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                  </a>
                </span>
              </div>
            );
          }

          // if (isAuth && item.id === 'bucket-object') {
          //   return (
          //       <div className="toggleNavItem">
          //         <Link to={item.link} 
          //           onClick={() => {
          //             dispatch('SET_SHOWTOGGLENAV', false);
          //             // dispatch('SET_DISPLAYTABLE', '');
          //           }}
          //         >
          //           <span className='toggleNavItemIcon'>
          //             {/* {marks.listUlMark} */}
          //             {marks.thLargeMark} 
          //           </span> 
          //           <span className='toggleNavItemText'>
          //             {item.text}
          //           </span>
          //         </Link>
          //       </div>
          //   );
          // }

          // if (isAuth && item.id === 'userinfo') {
          //   return (
          //       <div className="toggleNavItem">
          //         <Link to={item.link} 
          //           onClick={() => {
          //             dispatch('SET_SHOWTOGGLENAV', false);
          //           }}
          //         >
          //           <span className='toggleNavItemIcon'>
          //             {marks.userMrak} 
          //           </span> 
          //           <span className='toggleNavItemText'>
          //             {item.text}
          //           </span>
          //         </Link>
          //       </div>
          //   );
          // }

          // if (deferredPrompt && item.id === 'install-app') {
          //   return (
          //     <div className="toggleNavItem">
          //       <Link to={item.link} 
          //         onClick={() => {
          //           appInstallHandler(deferredPrompt);
          //           // dispatch('SET_SHOWTOGGLENAV', false);
          //         }}
          //       >
          //         <span className='toggleNavItemIcon'>
          //           {marks.plusSquareOMark} 
          //         </span> 
          //         <span className='toggleNavItemText'>
          //           {item.text}
          //         </span>
          //       </Link>
          //     </div>
          //   );          
          // }

          if (isAuth && item.id === 'logout') {
            return (
                <div className="toggleNavItem">
                  <span
                    onClick={() => { logoutConfirm(); }} 
                  >
                  <span className='toggleNavItemIcon'>
                    {marks.signOutMrak} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                  </span>
                </div>
            );
          }

          else {
            return null;
          }

          // return (
          //   <div  className="toggleNavItem">
          //     <span>
          //     <span className='toggleNavItemIcon'>
          //       {item.text}
          //       {/* {marks.userMrak}  */}
          //       </span> 
          //     </span>
          //   </div>
          // );
        })}
      </ul>
    </div>
  );

  return (
  <Fragment>
    {toggleNavItemsBody}
  </Fragment>
  );

  }
export default ToggleNavItems;
