import Resizer from "react-image-file-resizer";

export const resizeImageFile = (
  file, 
  outPut, 
  ext, 
  maxWidth, 
  maxHeight,
) => {
  const outputType = outPut ? outPut : 'base64';
  const extName = ext ? ext : "JPEG";
  // console.log(extName)

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      extName,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      outputType,
      0,
      0,
    );
  });
};