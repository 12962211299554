import React, { Fragment } from 'react';
import { Menu } from "lucide-react"
import { useHookStore } from '../../../../hook-store/store';

import classes from './ToggleNavButton.module.css';

const ToggleNavButton = props => {

  const [store, dispatch] = useHookStore();
  // console.log('store in ToggleNavButton', store);
  const showToggleNav = store.uiStore.showToggleNav;

  return (
    <Fragment>
      <span
        onClick={() => { dispatch('SET_SHOWTOGGLENAV', !showToggleNav); }}
      >
        <Menu />
      </span>
    </Fragment>
  );

  // return (
  //   <div className={classes.mobileToggle} 
  //     onClick={() => { dispatch('SET_SHOWTOGGLENAV', !showToggleNav); }}
  //   >
  //     <span className={classes.mobileToggle__bar} />
  //     <span className={classes.mobileToggle__bar} />
  //     <span className={classes.mobileToggle__bar} />
  //   </div>
  // )
};

export default ToggleNavButton;
