import { useState, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AboutBgRemove from './AboutBgRemove';
import AboutIllustGenerator from './AboutIllustGenerator';
import Backdrop from '../Backdrop/Backdrop';
import ControlNetDesc from './ControlNetDesc';
import Footer from '../Footer/Footer';
import Modal from '../Modal/Modal';
import ImagePhotoAuthInfo from '../UploadImage/ImagePhotoAuthInfo';
import { useHookStore } from '../../hook-store/store';

import { IMAGEPHOTO_BACKENDURL, kuraImagePhotoUrl } from '../../lib/api';

import InputImage from '../../image/for-iopaint/iopaint-input-200.png';
import MaskImage from '../../image/for-iopaint/iopaint-mask-200.png';
import InpaintImage from '../../image/for-iopaint/iopaint_cleanup-a-woman-face-200.png';
import CnImage from '../../image/for-iopaint/iopaint-controlnet-canny-200.png';

import ex2InputImage from '../../image/for-iopaint/candy-100.jpg';
import ex2MaskImage from '../../image/for-iopaint/candy-mask-100.png';
import ex2InpaintImage from '../../image/for-iopaint/candy-a-cat-face-100.png';
import ex3InputImage from '../../image/for-iopaint/lion-100.jpg';
import ex3MaskImage from '../../image/for-iopaint/lion-mask-100.png';
import ex3InpaintImage from '../../image/for-iopaint/lion-512-an-ancient-man-face-100.png';
import ex3Inpaint2Image from '../../image/for-iopaint/lion-512-an-ancient-woman-face-100.png';
import ex4InputImage from '../../image/for-iopaint/rg-apple-output-h150.jpeg';
import ex4MaskImage from '../../image/for-iopaint/rg-apple-mask-h150.png';
import ex4InpaintImage from '../../image/for-iopaint/rr-apple-output-h150.png';
import ex4InpaintImage2 from '../../image/for-iopaint/rg-apple-inpaint-orange-output-h150.png';
import ex5InputImage from '../../image/for-iopaint/boy-man-150.png';
import ex5MaskImage from '../../image/for-iopaint/boy-man-mask-h150.png';
import ex5InpaintImage from '../../image/for-iopaint/boy-man-cleanup-h150.png';
import ex5InpaintImage2 from '../../image/for-iopaint/boy-man-cleanup2-h150.png';

import { marks } from '../../utils/marks';
import classes from './AboutIopaint.module.css';



function AboutIopaint(props) {
  const { } = props;

  const [t] = useTranslation('translation');

  const bgRemoveRef = useRef(null);
  const illustGeneratorRef = useRef(null);

  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  const [showControlNetDesc, setShowControlNetDesc] = useState(false);

  const [showBgRemoveDetail, setShowBgRemoveDetail] = useState(false);
  const [showIllustDetail, setShowIllustDetail] = useState(false);
  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  const scrollToBgRemove = () => {
    bgRemoveRef.current.scrollIntoView();
    setShowBgRemoveDetail(true);
  };

  const scrollToIllustGenerator = () => {
    illustGeneratorRef.current.scrollIntoView();
    setShowIllustDetail(true);
  };



  

  

  let aboutIopaintBody;

  aboutIopaintBody = (
    <div>
      <Backdrop 
        zIndex={'95'}
        onCancel={() => { 
          hDispatch('SET_SHOWABOUTIOPAINT', false)
        }}
      />
      <Modal
        onClose={() => {
          hDispatch('SET_SHOWABOUTIOPAINT', false);
        }}
      >

        <div className={classes.aboutIopaintSection}>
          <div className={classes.aboutIopaintTitle}>
            About Kura Dream Inpainter
          </div>
          <div>
            <p>
              Kura Dream Inpainter 
              {' '}{t('aboutPage.01', 'has image editing tools that include')} 
              {' '}
              <strong>
                Kura Dream Inpainter, 
              </strong>
              {' '}
              <button onClick={scrollToBgRemove}>
                <strong>
                  Background Remover, 
                </strong>
              </button>
              {' '}
              <button onClick={scrollToIllustGenerator}>
                <strong>
                  Illustration Generator.
                </strong>
              </button>
            </p>
            <br/>
            <p>
              At Kura Dream Inpainter, 
              {' '}
              {t('aboutPage.02', 'user can modify specific parts of the image without affecting other parts.')}
              <br/>
              {t('aboutPage.03', 'Using advanced AI-technologies, selected part of image is replaced with generated new image that is based on input image and text prompt description.')}
            </p>
            {/* <p>
              {t('aboutPage.04', 'Generated image more precicely captures alignment of objects')}
              {' '}
              by using ControlNet. 
            </p> */}
            <br/>

            <p>
              {t('aboutPage.05', 'Generated Images can be shared')}
              {' '}
              <a href={'https://www.watakura.xyz'} 
                target="_blank"
                rel="noreferrer noopener">
                  at watakura
              </a>. 
              {' '}
              {t('aboutPage.06', 'NFTs can be created with images for trading')}
              {' '}
              <a href={'https://stellar-nftplace.spaceeight.work'} 
                target="_blank"
                rel="noreferrer noopener">
                  at Stellar NFT Place
              </a>.
              {' '}
              {t('aboutPage.07', 'Or, images can be stored')}
              {' '}
              <a href={`${kuraImagePhotoUrl}/about`} 
                target="_blank"
                rel="noreferrer noopener">
                  at Kura Image Photo
              </a>. 
            </p>
            <br/>

            <p>
              {t('aboutPage.08', 'Input image height or width should be less than 1024px. It is recommended to use image with 512px height or 512px width.')} 
              <br/>
              Output size is same as input size, but input image is resized to 512px size during image processing.
              {' '}
              <br/>
              {t('aboutPage.09', 'Image size can be resized')}
              {' '}
              <Link to={`${kuraImagePhotoUrl}/image-resizer`} 
                target="_blank"  rel="noreferrer noopener"
              >
                at Image Resizer.
              </Link>

            </p>
            <br/>
            <p>
              {t('aboutPage.10', 'JPEG or PNG files are accepted for input image.')}
            </p>
          </div>
          <br/>
          
          <div>
            <div>
              <strong>
                {t('aboutPage.11', 'Example of image inpainting')}
              </strong>
            </div>
            <br/>
            <div>
              {t('aboutPage.12', 'Image inpainting with text prompt description,')}
              {' '}
              "a woman face"
            </div>
            <br/>
            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={InputImage}
                />
                <div>{t('aboutPage.13', 'Input image')}</div>
              </div>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={MaskImage}
                />
                <div>{t('aboutPage.14', 'Selected part')}</div>
              </div>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={InpaintImage}
                />
                <div>{t('aboutPage.15', 'Generated image')}</div>
              </div>
              {/* <div>
                <img className={classes.aboutIopaintImage}
                  src={CnImage}
                />
                <div>with ControlNet</div>
              </div> */}
            </div>

            {/* <br/>
            <div>
              <div>
                <span onClick={() => {
                    setShowControlNetDesc(!showControlNetDesc);
                  }}
                >
                  {t('aboutPage.16', 'More details about')}
                  {' '}
                  ControlNet {marks.triangleDown}
                </span>
              </div>

              {showControlNetDesc && (
                <ControlNetDesc />
              )}
            </div> */}

            <br/>
            <br/>
            
            <div>{t('aboutPage.17', 'More examples')}</div>
            <br/>

            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex5InputImage}
                />
                <div>{t('aboutPage.13', 'Input image')}</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex5MaskImage}
                />
                <div>{t('aboutPage.14', 'Selected part')}</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex5InpaintImage}
                />
                <div>a man with sunglasses</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex5InpaintImage2}
                />
                <div>a man with sunglasses</div>
              </div>
            </div>
            <br/>    

            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex4InputImage}
                />
                <div>{t('aboutPage.13', 'Input image')}</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex4MaskImage}
                />
                <div>{t('aboutPage.14', 'Selected part')}</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex4InpaintImage}
                />
                <div>a red apple</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex4InpaintImage2}
                />
                <div>an orange</div>
              </div>
            </div>
            <br/>    

            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex3InputImage}
                />
                <div>{t('aboutPage.13', 'Input image')}</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex3MaskImage}
                />
                <div>{t('aboutPage.14', 'Selected part')}</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex3InpaintImage}
                />
                <div>a man face</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex3Inpaint2Image}
                />
                <div>a woman face</div>
              </div>
            </div>
            <br/>

            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex2InputImage}
                />
                <div>{t('aboutPage.13', 'Input image')}</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex2MaskImage}
                />
                <div>{t('aboutPage.14', 'Selected part')}</div>
              </div>
              <div>
                <img className={classes.aboutMoreIopaintImage}
                  src={ex2InpaintImage}
                />
                <div>a cat face</div>
              </div>
            </div>

          </div>
        </div>

        
        <div ref={bgRemoveRef}>
          <br/>
          <br/>
          <div>
            <span
              onClick={() => { setShowBgRemoveDetail(!showBgRemoveDetail) }}
            >
              About Background Remover {marks.triangleDown}
            </span>
          </div>
          <br/>
          
          {showBgRemoveDetail && (
            <AboutBgRemove />
          )}
        </div>

        <div ref={illustGeneratorRef}>
          <br/>
          <br/>
          <div>
            <span
              onClick={() => { setShowIllustDetail(!showIllustDetail) }}
            >
              About Illustration Generator {marks.triangleDown}
            </span>
          </div>
          <br/>

          {showIllustDetail && (
            <AboutIllustGenerator />
          )}
        </div>

        <br/>
        <br/>
        <div className={classes.aboutIopaintSection}>
          <ImagePhotoAuthInfo />
        </div>

        <div>
          <Footer />
        </div>
      </Modal>
    </div>
  )


  
  return (
    <Fragment>
      {aboutIopaintBody}
    </Fragment>
  );
}

export default AboutIopaint;
