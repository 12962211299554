import { useState, useEffect, Fragment } from 'react';
// import axios from 'axios';

import * as bucketUtils from '../../../utils/bucket/bucket-util';

import { useHookStore } from '../../../hook-store/store';
// import { useStore } from '../../../hook-store/store';
// import { BACKEND_URL } from '../../../App';
import { IMAGEPHOTO_BACKENDURL } from '../../../lib/api';

function Bucket(props) {

  const [store, dispatch] = useHookStore();
  const gLoading = store.uiStore.gLoading;

  const [buckets, setBuckets] = useState([]);
  const [bucketInput, setBucketInput] = useState('');

  useEffect(() => {
    // getBuckets();
    getUserBucketHandler(localStorage.getItem('userId'));
  },[]);

  // useEffect(() => {
  //   dispatch('SET_BUCKETNAME', 'test-access-bucket');
  // },[]);

  const getBuckets = async () => {
    try {
      const buckets = await bucketUtils.getBuckets(IMAGEPHOTO_BACKENDURL, localStorage.getItem('token'));

      console.log(buckets);
      setBuckets(buckets.data.Buckets);

    } catch(err) {
      console.log(err);
    }
  };

  const bucketInputHandler = (event) => {
    // console.log(event.target.value);
    setBucketInput(event.target.value);
  }

  const createBucketHandler = async (bucketname) => {
    try {
      const result = await bucketUtils.createBucket(
        IMAGEPHOTO_BACKENDURL,
        localStorage.getItem('token'),
        bucketname,
      );
      console.log(result);

    } catch(err) {
      console.log(err);
    }
  };


  const getUserBucketHandler = async (userId) => {
    try {
      dispatch('SET_GLOADING', true);

      const bucket = await bucketUtils.getUserBucket(
        IMAGEPHOTO_BACKENDURL, 
        localStorage.getItem('token'),
        // userId,
        userId + '-image-photo',
      );

      console.log(bucket);

      if (bucket === 'not-found') {
        // await createBucketHandler(userId);
        // getUserBucketHandler(userId);
        await createBucketHandler(userId + '-image-photo');
        getUserBucketHandler(userId + '-image-photo');
      }

      dispatch('SET_BUCKETNAME', bucket.data.Name);

      dispatch('SET_GLOADING', false);
      // setBuckets(buckets.data.Buckets);

    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
    }
  };

  // const onChangeHandler = (event) => {
  //   console.log(event.target.files[0]);
  //   setSelectedFile(event.target.files[0]);
  // };

  // const onClickHandler = () => {
  //   const data = new FormData(); 
  //   data.append('file', selectedFile);
  //   data.append('bucket', 'test-access-bucket');

  //   axios.post("http://localhost:4000/bucket/object", data, { 
  //     // receive two    parameter endpoint url ,form data
  //   })
  //   .then(res => { // then print response status
  //     console.log(res);
  //     // console.log(res.statusText)
  //   }).catch(err => {
  //     console.log(err);
  //   });
  // };

  let bucketListBody;

  if (buckets.length > 0) {
    bucketListBody = (
      <ul>{buckets.map((bucket, index) => {
        return (
          <div key={index}>
            <div>{bucket.Name}</div>
            <div>{bucket.CreationDate}</div>
            <hr/>
          </div>
        );
      })}</ul>
    );
  }

  return (
    <Fragment>
      {/* <div>bucket-page-content</div>
      <div>{bucketListBody}</div>

      <label htmlFor="bucket" >bucket-name</label>
      <input type='text' name="bucket" onChange={bucketInputHandler}/>
      <button onClick={() => {createBucketHandler(bucketInput); }}>create-bucket</button>
       */}
      {/* <input type="file" name="file" onChange={onChangeHandler}/>
      <button type="button" class="btn btn-success btn-block" onClick={onClickHandler}>Upload</button>  */}
    </Fragment>
  );
}

export default Bucket;
