import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";


import { useStore } from '../../lib/states';
import { srcToFile } from '../../lib/utils';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import ImagePhotoAuthInfo from '../UploadImage/ImagePhotoAuthInfo';
import { useHookStore } from '../../hook-store/store';

import { IMAGEPHOTO_BACKENDURL, kuraImagePhotoUrl } from '../../lib/api';

import { marks } from '../../utils/marks';
import './InpaintFiles.css';
// import classes from './AboutIopaint.module.css';



function InpaintFiles(props) {

  const { 

  } = props;

  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  // let [searchParams, setSearchParams] = useSearchParams();
  
  const [
    file, 
    editorState,
  ] = useStore((state) => [
    state.file,
    state.editorState,
  ])
  const renders = editorState.renders;


  const [hStore, hDispatch] = useHookStore();
  const { currentInpaintFile } = hStore.iopaintStore;
  // console.log('store-BucketObjects.js', store);

  // const [currentRenderFile, setCurrentRenderFile] = useState();


  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  const openFileInPageHandler = (fileType, page) => {
    searchParams.delete('inpaintFile');
    setSearchParams(searchParams);

    let queryParams = window.location.search;

    if (fileType) {
      if (!queryParams) {
        queryParams = queryParams + `?inpaintFile=${fileType}`;
      } else {
        queryParams = queryParams + `&inpaintFile=${fileType}`;
      }
    }

    navigate(`/${page}${queryParams}`);
  }

  let inpaintFilesBody;

  if (!file && !currentInpaintFile) {
    inpaintFilesBody = (
      <div>
        No files. 
      </div>
    );
  } else {
    inpaintFilesBody = (
      <div>
              {currentInpaintFile && (
          <div>
            <div>Edited File</div>
            <div className='inpaintFilesSection'>
              <img 
                className='inpaintFilesImage'
                src={URL.createObjectURL(currentInpaintFile)}
              />
            </div>
            <div className='inpaintFilesSection'>
              <div className='inpaintFilesButtons'>
                <button className='iopaintBtnBase'
                  onClick={() => {
                    openFileInPageHandler('inpaint', 'background-remover')
                  }}
                >
                  Open in Background Remover {marks.moveMark}
                </button>
                <button className='iopaintBtnBase'
                  onClick={() => {
                    openFileInPageHandler('inpaint', 'illustration-generator')
                  }}
                >
                  Open in Illustration Generator {marks.moveMark}
                </button>
              </div>
            </div>
          </div>
        )}
  
        {file && (
          <div>
            <div>Input file</div>
            <div className='inpaintFilesSection'>
              <img 
                className='inpaintFilesImage'
                src={URL.createObjectURL(file)}
              />
            </div>
            <div className='inpaintFilesSection'>
              <div className='inpaintFilesButtons'>
                <button className='iopaintBtnBase'
                  onClick={() => {
                    openFileInPageHandler('input', 'background-remover')
                  }}
                >
                  Open in Background Remover {marks.moveMark}
                </button>
                <button className='iopaintBtnBase'
                  onClick={() => {
                    openFileInPageHandler('input', 'illustration-generator')
                  }}
                >
                  Open in Illustration Generator {marks.moveMark}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }



  
  return (
    <Fragment>
      <Backdrop 
        zIndex={'95'}
        onCancel={() => { 
          hDispatch('SET_SHOWINPAINTFILES', false)
        }}
      />
      <Modal
        onClose={() => {
          hDispatch('SET_SHOWINPAINTFILES', false);
        }}
      >
        {inpaintFilesBody}
      </Modal>
    </Fragment>
  );
}

export default InpaintFiles;
