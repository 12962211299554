import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useHookStore } from '../../hook-store/store';

import classes from './Footer.module.css';


// import { Link } from 'react-router-dom';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';

function Footer(props) {
  // console.log('Layout.js-props', props);

  const [t] = useTranslation('translation');

  const [store, dispatch] = useHookStore();
  const { isAuth } = store.uiStore;
  // console.log('store- Layout.js', store);


  return (
    <Fragment>

      <div id='footer-el'
        className={classes.footer}
      >
        <div className={classes.footerLinks}>
          {/* <span className={classes.footerLink}>
            <Link to="/about">{t('footer.03', 'About page')}</Link>
          </span> */}
          <span className={classes.footerLink}>
            <Link to="/privacypolicy">{t('footer.01', 'privacy policy')}</Link>
          </span>
          <span className={classes.footerLink}>
            <Link to="/termsofuse">{t('footer.02', 'terms of use')}</Link>
          </span>
          {/* {isAuth && (
              <span className={classes.footerLink}>
                <Link to="/contact">{t('footer.04', 'Contact form')}</Link>
              </span>
          )} */}
        </div>
        <div className={classes.footerLinks}>
          <span className={classes.footerLink}>
            Kura Dream Inpainter for Kura Image Photo by SpaceEight
          </span>
        </div>
      </div>

    </Fragment>
  );
}

export default Footer;
