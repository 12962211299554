import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useHookStore } from '../../hook-store/store';

import BgRemoveInputImage from '../../image/for-bg-remove/coffee-cups-150.jpg';
import BgRemoveRemoveImage from '../../image/for-bg-remove/coffee-cups-romove-bg-150.png';
import BgRemoveInputImage2 from '../../image/for-bg-remove/man-150.png';
import BgRemoveRemoveImage2 from '../../image/for-bg-remove/man-bg-remove-150.png';


import classes from './AboutIopaint.module.css';



function AboutBgRemove(props) {
  const { } = props;

  const [t] = useTranslation('translation');
  
  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);



  

  

  let aboutBgRemoveBody;

  aboutBgRemoveBody = (
    <div>
        <div className={classes.aboutIopaintSection}>
          <div>
            <strong>
              Background Remover
            </strong>
          </div>
          <div className={classes.aboutIopaintSection}>
            <p>
              {t('aboutBgRemove01', 'Advanced AI-technologies enabled to delete background from image.')}
            </p>
          </div>

          <div>
            <div>
              <strong>
              {t('aboutBgRemove02', 'Example of background remove')}
              </strong>
            </div>
            <br/>
            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={BgRemoveInputImage}
                />
                <div>{t('aboutBgRemove03', 'Input image')}</div>
              </div>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={BgRemoveRemoveImage}
                />
                <div>{t('aboutBgRemove04', 'Removed Image')}</div>
              </div>
            </div>
            <br/>

            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={BgRemoveInputImage2}
                />
                <div>{t('aboutBgRemove03', 'Input image')}</div>
              </div>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={BgRemoveRemoveImage2}
                />
                <div>{t('aboutBgRemove04', 'Removed Image')}</div>
              </div>
            </div>

        </div>
        <br/>
        <div>
          <Link to="/background-remover">
            Go to Background Remover Page
          </Link>
        </div>
      </div>
    </div>
  )


  
  return (
    <Fragment>
      {aboutBgRemoveBody}
    </Fragment>
  );
}

export default AboutBgRemove;
