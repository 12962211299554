import { Fragment, useEffect, useState } from "react";

import { IconButton } from "../../ui/button"

import Backdrop from "../../Backdrop/Backdrop";
import ToggleNav from "../../Navigation/ToggleNav/ToggleNav/ToggleNav"
import ToggleNavButton from "../../Navigation/ToggleNav/ToggleNavButton/ToggleNavButton"

import { useHookStore } from "../../../hook-store/store";
// import "./App.css";
// import "./style.css";
import "./BgRemoveHeader.css";

function BgRemoveHeader() {

  const [hStore, hDispatch] = useHookStore();
  const { 
    showToggleNav,
   } = hStore.uiStore;

  const [showEl, setShowEl] = useState(true);

  useEffect(() => {
    if (!showEl) {
      setShowEl(true);
    }
  },[showEl]);
  return (
    <Fragment>
      <ToggleNav />
        <div className="bgRemoveHeader">
          <div></div>
          <IconButton
            // disabled={isInpainting}
            tooltip="Menu"
            onClick={() => {}}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
          >
            <ToggleNavButton /> 
          </IconButton>
        </div>
        {showToggleNav && (
            <Backdrop 
              zIndex={'10'}
              // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            //  backgroundColor={'rgba(0, 0, 0, 0)'}
              onCancel={() => { 
                hDispatch('SET_SHOWTOGGLENAV', false); 
              }}
            />
          )}
    </Fragment>
  );
}

export default BgRemoveHeader;
