import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useHookStore } from '../../hook-store/store';

// import { kuraImagePhotoUrl } from '../../lib/api';

import CannyInput from '../../image/for-control-net-desc/canny-input-canny-crop-150.png';
import CannyImage from '../../image/for-control-net-desc/canny-images_control-canny-crop-150.png';
import CannyOutput from '../../image/for-control-net-desc/canny-images_image_out-canny-crop-150.png';
import OpenposeInput from '../../image/for-control-net-desc/openpose-images_input-openpose-150.jpg';
import OpenposeImage from '../../image/for-control-net-desc/openpose-images_control-openpose-150.png';
import OpenPoseOutput from '../../image/for-control-net-desc/openpose-images_image_out-openpose-150.png';
import InpaintInput from '../../image/for-control-net-desc/boy-input-inpaint-crop-150.png';
import InpaintImage from '../../image/for-control-net-desc/boy_with_mask-inpaint-crop-150.png';
import InpaintOutput from '../../image/for-control-net-desc/boy_ray_ban-inpaint-crop-150.png';
import DepthInput from '../../image/for-control-net-desc/depth-images_input-depth-150.png';
import DepthImage from '../../image/for-control-net-desc/depth-images_control-depth-150.png';
import DepthOutput from '../../image/for-control-net-desc/depth-images_image_out-depth-150.png';

import classes from './AboutIopaint.module.css';



function ControlNetDesc(props) {
  const { } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);



  

  

  let controlNetDescBody;

  controlNetDescBody = (
    <div>
        <div className={classes.aboutIopaintSection}>
            <br/>

            <div>
              <div>
                ControlNet 
                {' '}
                {t('aboutControlNet01', 'create a image that is depend on used method.')} 
                {' '}
                {t('aboutControlNet02', 'Using the created image, it generate a image that match prompt descriptions.')}
                <br/>
                <br/>
                In Kura Dream Inpainter, ControlNet 
                {' '}
                {t('aboutControlNet03', 'create a image of user selected region.')} 
                {' '}
                {t('aboutControlNet04', 'Generated image will be based on entire input image and prompt descriptions for selected region.')}
              </div>
              <br/>
              <div>
                <strong>Canny</strong>
              </div>
              <div>
                Canny 
                {' '}
                {t('aboutControlNet05', 'create a white outline of image on black background.')}
                </div>
              <br/>
              <div>a blue paradise bird in the jungle</div>
              <div className={classes.aboutIopaintImageContainer}>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={CannyInput}
                  />
                  <div>Input image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={CannyImage}
                  />
                  <div>Canny image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={CannyOutput}
                  />
                  <div>Output image</div>
                </div>
              </div>
            </div>
            <br/>

            <div>
              <div>
                <strong>OpenPose</strong>
              </div>
              <div>
                OpenPose 
                {' '}
                {t('aboutControlNet06', 'detect human body, hand, facial, and foot keypoints on images.')}
              </div>
              <br/>

              <div>a chef in the kitchen</div>
              <div className={classes.aboutIopaintImageContainer}>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={OpenposeInput}
                  />
                  <div>Input image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={OpenposeImage}
                  />
                  <div>OpenPose image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={OpenPoseOutput}
                  />
                  <div>Output image</div>
                </div>
              </div>
            </div>
            <br/>

            <div>
              <div>
                <strong>Inpaint</strong>
              </div>
              <div>
                Inpaint 
                {' '}
                {t('aboutControlNet07', 'generate a image of masked region based on input image and text prompt descriptions.')}
              </div>
              <br/>

              <div>a handsome man with sunglasses</div>
              <div className={classes.aboutIopaintImageContainer}>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={InpaintInput}
                  />
                  <div>Input image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={InpaintImage}
                  />
                  <div>Masked image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={InpaintOutput}
                  />
                  <div>Output image</div>
                </div>
              </div>
            </div>
            <br/>

            <div>
              <div>
                <strong>Depth</strong>
              </div>
              <div>
                Depth
                {' '} 
                {t('aboutControlNet08', 'detect depth information of the image.')}
              </div>
              <br/>

              <div>Stormtrooper's lecture in beautiful lecture hall</div>
              <div className={classes.aboutIopaintImageContainer}>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={DepthInput}
                  />
                  <div>Input image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={DepthImage}
                  />
                  <div>Depth image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={DepthOutput}
                  />
                  <div>Output image</div>
                </div>
              </div>
            </div>
            <br/>

        </div>
      </div>
  )


  
  return (
    <Fragment>
      {controlNetDescBody}
    </Fragment>
  );
}

export default ControlNetDesc;
