// const React = window.React = require('react');
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const HeadMetaTag = (props) => {

  const [t] = useTranslation('translation');

  // const title = `Kura Dream Inpainter - ${t('headMeta01', 'Simple image and file storage with various image editing tools')}`
  const title = `Kura Dream Inpainter - simple image inpainting and editing tools`
  
  const description = `Kura Dream Inpainter by SpaceEight. 
${t('headMeta01', 'Tools for image editing.')}

At Kura Dream Inpainter, ${t('headMeta02', 'user can modify specific parts of the image without affecting other parts.')}

At Background Remover, ${t('headMeta03', 'advanced AI-technologies enabled to delete background from image.')}

Illustration Generator ${t('headMeta04', 'is a tool to generate a illustration image from a input image.')}
${t('headMeta05', 'Input picture image can be converted into illustarion image.')}
`;

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <title>Page 1</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
    </Fragment>
  );
}

export default HeadMetaTag;